<template>
  <LoaderComponent isLoading="true" type="warning"></LoaderComponent>
</template>
<script>
import LoaderComponent from "@/components/LoaderComponent";

export default {
  name: "RedirectPage",
  components: {
    LoaderComponent,
  },
  mounted() {
    console.log("Mounted");
    if (this.$store.getters.isAuthenticated) {
      window.location.href = "/supervisor";
    }
  },
  created() {
    console.log("Created");
    this.$store.dispatch("parseHash", { hash: window.location.hash });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
