<template>
    <form id="frmCloseIncident" name="frm-close-incident" ref="frmCloseIncident">
        <PrimeDialog v-model:visible="visible" modal :header="headerText" @hide="fnCancel"
                     :draggable="false" :style="{ width: '50rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

            <!-- Close ReasonType -->
            <div class="mb-1">
                <span class="inline-block w-32 font-bold">Close Reason:</span>{{ fnCapText(closeReason) }}
            </div>
            <div class="mb-1">
                <div v-if="isResolved" class="inline-block w-32 font-bold align-top">Actions taken:</div>
                <div v-else class="inline-block w-32 font-bold align-top">Cancel reasons:</div>
                <div class="inline-block w-4/5">
                    <div v-if="v$.entity.closeComments.$error" class="text-red-600 text-sm">Please enter a closing comments.</div>
                    <Textarea v-model="entity.closeComments" rows="6" class="w-full md:w-16rem" />
                </div>
            </div>
            
            <template #footer>
                <Button label="Back"
                        class="mr-2 bg-surface-400 border-surface-400 hover:bg-surface-500"
                        title="Cancel and go back" @click="fnCancel"></Button>
                <Button v-if="isResolved" label="Resolve" severity="success"
                        title="Resolve the incident." @click="fnClose"></Button>
                <Button v-else label="Close" severity="warning"
                        title="Close the incident." @click="fnClose"></Button>
            </template>
        </PrimeDialog>
    </form>
</template>

<script>
    import Textarea from 'primevue/textarea';
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";

    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    //import { required, requiredIf } from '@vuelidate/validators'

    import { incidentService } from "@/services/IncidentService.js";
    import { capitalizeFirstLetter } from "@/utils/utils.js";
    import Constants from "@/utils/Constants.js";

    export default {
        name: "IncidentCloseComponent",
        emits: ["onError", "onClose", "onCancel"],
        setup() {
            return { v$: useVuelidate({ $scope: "close" }) }
        },
        components: {
            Textarea, Button,
            PrimeDialog
        },
        props: {
            entityId: String,
            entityName: String,
            isOpen: Boolean,
            closeReason: String
        },
        data() {
            return {
                entity: {
                    entityId: undefined,
                    closedBy: undefined,
                    closeComments: undefined,
                    closeReason: undefined,
                },
                isResolved: false,
                visible: false,
                headerText: "Incident"
            }
        },
        validations() {
            return {
                entity: {
                    closeComments: { required }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {
                    console.log("Incident Close Component Opened!", this.closeReason);
                    this.entity.entityId = this.entityId;
                    this.entity.closeReason = this.closeReason;
                    this.entity.closedBy = this.$store.getters.username;
                    this.isResolved = this.closeReason == Constants.Incident.CLOSE_REASON.RESOLVED;
                    this.headerText = this.isResolved ? "Resolve the Incident: " + this.entityName : "Close the Incident: " + this.entityName ;
                    this.visible = true;
                }
                else {
                    this.visible = false;
                }
            }
        },
        methods: {
            fnClose() {
                this.v$.$validate()
                    .then(result => {
                        
                        if (result) {
                            this.loading = true;
                            
                            console.log(JSON.stringify(this.entity));
                            incidentService.close(this.entity)
                                .then((data) => {
                                    //console.log(data);
                                    this.entity.closeDate = new Date(data.closedDate);
                                    
                                    this.$emit('onClose', this.entity);

                                    this.loading = false;

                                    this.fnCleanForm();
                                    this.$emit('onCancel');
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('onError', "Error", 'An error occurred when trying to close the Incident: "' + this.entityName + '"!!!', ToastSeverity.ERROR);
                                    this.loading = false;
                                });

                            this.loading = false;
                                
                            return true;
                        }

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                return false;
            },
            fnCancel() {
                this.fnCleanForm();
                this.$emit('onCancel');
                this.visible = false;
            },
            fnCleanForm: function () {
                this.entity = {
                    entityId: undefined,
                    closedBy: undefined,
                    closeComments: undefined,
                    closeReason: undefined
                };
                this.$refs.frmCloseIncident.reset();
                this.v$.$reset();
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
        }
    };
</script>
<style scoped>

</style>

