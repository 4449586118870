<template>
  <div
    v-for="(product, pro_key) in data"
    :key="pro_key"
    class="flex-0 mb-4"
    style="margin-bottom: 64px"
  >
    <div class="m-2 w-full mb-2">
      <span class="font-medium">{{ pro_key }}</span>
    </div>
    <div
      class="bg-yellow-50 py-8 shadow-md mb-2"
      style="padding-top: 40px; padding-bottom: 40px"
    >
      <div v-for="(sub_value, sub_key) in product" :key="sub_key">
        <div class="mt-1 text-center font-semibold w-full mb-3">
          {{ sub_key }}
        </div>
        <div class="flex flex-wrap mt-4 justify-center gap-4">
          <div v-for="(value, key) in sub_value" :key="key">
            <div class="mx-4 flex items-center">
              <div class="flex items-center justify-center">
                <Avatar
                  icon="pi pi-wave-pulse"
                  class="mr-2"
                  shape="circle"
                  style="background-color: #f8f7fc; color: #f82c7c"
                />
              </div>
              <div>
                <div>
                  <div class="m-0 text-center font-bold text-4xl">
                    {{ value }}
                  </div>
                </div>
                <div>
                  <div class="text-base">{{ key }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Card from "primevue/card";
import Avatar from "primevue/avatar";

export default {
  components: {
    // Card,
    Avatar,
  },
  props: {
    chartObject: Object,
    url: String,
  },
  data() {
    return {
      data: "",
      header: "",
      chart: "",
      dynamicColor: "white",
      conf: {},
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Value");
        console.log(newValue);
        this.header = newValue.name;
        this.chart = newValue;
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      console.log("--------- BIG CARD ----------");
      console.log(`${this.$apiBase}/${this.url}`);
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          console.log(response.data);
          this.data = response.data.data;
          this.conf = response.data.conf;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
