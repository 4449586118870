<template>
    <div v-show="isConfirmationBoxOpen"
         class="flex justify-center bg-gray-100 bg-opacity-75 overflow-y-auto overflow-x-hidden fixed z-50 md:inset-0 md:h-full">
        <div class="relative p-4 w-full max-w-md md:h-auto flex items-center justify-center">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button @click="$emit('close')"
                        type="button"
                        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                    <svg aria-hidden="true"
                         class="w-5 h-5"
                         fill="currentColor"
                         viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <div class="p-6 text-center">
                    <svg aria-hidden="true"
                         class="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {{ msg }}
                    </h3>
                    <div class="my-6">
                        <form id="frmChangePassword">
                            <div v-if="v$.password.$error" class="text-red-600 text-sm">Please enter the password.</div>
                            <FormInput type="password" placeholder="Password" :hasError="v$.password.$error" v-model="password" name="confPassword"></FormInput>
                        </form>
                    </div>
                    <button @click="fnOk" type="button"
                            class="text-white bg-indigo-800 hover:bg-indigo-950 focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                        Yes, I'm sure
                    </button>
                    <button @click="$emit('close')" type="button"
                            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        No, cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import FormInput from "@/components/FormInput";

    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    export default {
        name: "ConfirmationBoxComponent",
        emit: ["ok", "close"],
        components: {
            FormInput,
        },
        props: {
            msg: String,
            isConfirmationBoxOpen: Boolean
        },
        data() {
            return {
                password: ""
            };
        },
        setup() {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                password: { required }
            }
        },
        methods: {
            fnOk() {
                this.v$.$validate()
                    .then((result) => {
                        if (result) {
                            this.$emit('ok', this.password);
                        }
                    });
            }
        }
    };
</script>
