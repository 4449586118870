import axios from "axios";
import { axiosService } from "@/services/AxiosService.js";
import store from "../security/store.js";

class LogService {

    constructor() {
      this.ip = undefined;
      this.subPath = "app-log";
      this.ipify = "api.ipify.org";
      this.protocol = window.location.protocol;
    }

  save(page) {
    let actionPath = `${this.subPath}/save`;
    console.log(`Post to: ${actionPath}`);

    let entity = {
      projectId: store.getters.project,
      username: store.getters.username,
      page: page,
      ipPublic: this.ip
    };

    if (this.ip == undefined) {
      this.getPublicIP(actionPath, entity);
    }
    else {
      this.doSave(actionPath, entity);
    }
  }

  doSave(actionPath, entity) {
    axiosService.doPost(actionPath, entity)
      .then((data) => {
        console.log("Success saving Log activity.", data);
      })
      .catch((error) => {
        console.log("ERROR when saving Log activity", error)
      });
  }

    get(entityId) {
      let actionPath = `${this.subPath}/get/${entityId}`;
      console.log(`Get to: ${actionPath}`);
      
      return axiosService.doGet(actionPath);
    }

    find(projectId) {
      let actionPath = `${this.subPath}/find/${projectId}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

    delete(entityId) {
      const actionPath = `${this.subPath}/delete/${entityId}`;
      console.log(`Delete to: ${actionPath}`);

      return axiosService.doDelete(actionPath);
  }

  getPublicIP(actionPath, entity) {
    let ax = axios.create();
    ax.get(`${this.protocol}//${this.ipify}`)
    .then((response) => {
      if (response != undefined && response.status == 200) {
        this.ip = response.data;
        entity.ipPublic = this.ip;
      }
      else {
        console.log("Error when getting Public IP: ", response.status);
      }
      this.doSave(actionPath, entity);
    })
    .catch((error) => {
      delete error['stack'];
      console.error(error);
      this.doSave(actionPath, entity);
    })
  }
}

export const logService = new LogService();
