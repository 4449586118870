import { createStore } from "vuex";
import KeycloakService from "./keycloak"; // Adjust the path as per your file structure
import { parseJwt } from "../utils/utils.js";

export default createStore({
  state: {
    isAuthenticated: false,
    user: null,
    accessToken: localStorage.getItem("dss-access-token"),
    roles: [],
    username: undefined,
    tokenExpiry: undefined,
    accessExpiry: undefined,
    status: "",
    project: localStorage.getItem("dss-project") || undefined,
  },
  mutations: {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN_EXPIRY: (state, refresh, access) => {
      state.tokenExpiry = refresh;
      state.accessExpiry = access;
    },
    SUCCESS: (state, token, refreshToken) => {
      state.status = "success";
      state.accessToken = token;
      state.refreshToken = refreshToken;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
    },
    SET_PROJECT: (state, projectId) => {
      localStorage.setItem("dss-project", projectId);
      state.project = projectId;
    },
  },
  actions: {
    async login({ commit, dispatch }) {
      try {
        await KeycloakService.CallInit(async () => {
          const authenticated = await KeycloakService.CallTokenRefresh();
          if (authenticated) {
            const keycloak_inst = KeycloakService.keycloak;
            const user = KeycloakService.keycloak.loadUserInfo();
            //console.log(KeycloakService.keycloak);
            commit("SET_USER", user);
            commit("SET_AUTHENTICATED", true);
            localStorage.setItem("dss-access-token", keycloak_inst.token);
            localStorage.setItem(
              "dss-refresh-token",
              keycloak_inst.refreshToken
            );
            localStorage.setItem(
              "dss-token-expiry",
              keycloak_inst.refreshTokenParsed.exp
            );
            localStorage.setItem(
              "dss-access-expiry",
              keycloak_inst.tokenParsed.exp
            );
            commit(
              "SET_TOKEN_EXPIRY",
              keycloak_inst.refreshTokenParsed.exp,
              keycloak_inst.tokenParsed.exp
            );
            commit("SUCCESS", keycloak_inst.token, keycloak_inst.refreshToken);
            dispatch("setPermissions");
            KeycloakService.keycloak.authenticated = true;
          }
        });
      } catch (error) {
        console.error("Login failed:", error);
      }
    },
    setPermissions({ commit, getters }) {
      console.log("setPermissions...");
      //console.log(getters.accessToken);
      //console.log(localStorage.getItem("dss-access-token"));
      if (getters.accessToken) {
        let decodedToken = parseJwt(getters.accessToken);
        //console.log(decodedToken);

        let roles = [];

        if (
          "resource_access" in decodedToken &&
          "vuejs" in decodedToken["resource_access"]
        ) {
          roles = decodedToken["resource_access"]["vuejs"]["roles"];
        }
        //console.log(roles);
        commit("SET_ROLES", roles);
        //console.log("UserName: " + decodedToken["preferred_username"]);
        commit("SET_USERNAME", decodedToken["preferred_username"]);
      }
    },
    parseHash({ getters, commit, dispatch }) {
      console.log("in parse hash");
      if (!getters.isAuthenticated) {
        console.log("Access Token is undefined or empty.");
        commit("logout");
        return;
      }
      const now = new Date();
      if (now > getters.accessExpiry - 5 * 60 * 1000) {
        console.log("Access Token is Expired.");
        dispatch("refreshAccessToken");
      }
    },
    doLogout() {
      KeycloakService.CallLogout(window.location.origin);
    },
    async refreshAccessToken({ commit, getters }) {
      console.log("refreshAccessToken - triggered");

      if (!getters.refreshToken) {
        console.log("refreshAccessToken - token: There is NO refresh token.");
        commit("logout");
      }

      try {
        const keycloak = await KeycloakService.CallTokenRefresh();
        if (keycloak) {
          localStorage.setItem("dss-access-token", keycloak.token);
          localStorage.setItem("dss-access-expiry", keycloak.tokenParsed.exp);
        }
      } catch (error) {
        commit("logout");
      }
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    accessToken: (state) => state.accessToken,
    roles: (state) => state.roles,
    username: (state) => state.username,
    tokenExpiry: (state) => state.tokenExpiry,
    accessExpiry: (state) => state.accessExpiry,
    project: (state) => state.project,
  },
});
