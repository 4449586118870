<template>
    <div class="container">
        <div v-for="(cluster, index) in clusters"
             :key="index"
             class="grid-container"
             :style="getGridStyle(cluster)">
            <div v-for="chart in cluster"
                 :key="chart.id"
                 :style="getChartStyle(chart)">
                <component :is="getComponent(chart)"
                           :chartObject="chart"
                           :url="getUrl(chart)"
                           @recordUpdated="fnOnUpdate"
                           :reload="reloadComponents"
                           @componentClick="fnOnClick"
                           :clickEvent="chart.clickEvent" />
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import CustomRichDataTableComponent from "@/components/Tables/CustomRichDataTableComponent";
    import CustomBasicDataTableComponent from "@/components/Tables/CustomBasicDataTableComponent";
    import CustomTableComponent from "@/components/Tables/CustomTableComponent";
    import IframeComponent from "@/components/Iframe/IframeComponent";
    import BigText from "@/components/Text/BigText";
    import LineChartComponent from "@/components/Charts/LineChartComponent";
    import BarChartComponent from "@/components/Charts/BarChartComponent";
    import BigCard from "@/components/Text/BigCard";
    import MultiCards from "@/components/Cards/MultiCards";
    import RunSimulator from "@/components/Buttons/RunSimulator";

    export default {
        components: {
            CustomRichDataTableComponent,
            CustomBasicDataTableComponent,
            CustomTableComponent,
            IframeComponent,
            BigText,
            LineChartComponent,
            BarChartComponent,
            BigCard,
            MultiCards,
            RunSimulator,
        },
        props: {
            tab: String,
        },
        data() {
            return {
                sortedCharts: [],
                clusters: {},
                reloadComponents: 0 
            };
        },
        computed: {},
        mounted() {
            this.fetchCharts();
        },
        methods: {
            async fetchCharts() {
                try {
                    const response = await axios.get(`${this.$apiBase}/widget/${this.tab}`);
                    const charts = response.data.charts;
                    this.sortedCharts = charts.sort((a, b) => a.layout[0] - b.layout[0]);
                    this.createClusters();
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            },
            getComponent(chart) {
                const componentMap = {
                    BigText: "BigText",
                    RichTable: "CustomRichDataTableComponent",
                    BasicTable: "CustomBasicDataTableComponent",
                    Iframe: "IframeComponent",
                    BarChart: "BarChartComponent",
                    TextBigCard: "BigCard",
                    LineChart: "LineChartComponent",
                    MultiCards: "MultiCards",
                    RunSimulator: "RunSimulator",
                    Schedule: "CustomTableComponent",
                };
                return componentMap[chart.type] || "div";
            },
            getUrl(chart) {
                const todayFormatted = new Date().toISOString().split("T")[0];
                const urlMap = {
                    BigText: `widget/big-text/${this.$store.getters.project}/${chart.configuration}`,
                    RichTable: {
                        onLoad: chart.subType?.includes("mongo")
                            ? `widget/richTable/${this.$store.getters.project}/${chart.configuration}`
                            : ``,
                        onRowSelect: chart.subType?.includes("mongo") ? `widget/row` : ``,
                        onUpdate: chart.subType?.includes("mongo") ? `widget/richTable` : ``,
                    },
                    BasicTable: {
                        onLoad: chart.subType?.includes("type")
                            ? `stellio/basicTable/${this.$store.getters.project}/${chart.configuration}`
                            : `widget/basicTable/${this.$store.getters.project}/${chart.configuration}`,
                        onRowSelect: chart.subType?.includes("type")
                            ? `stellio/expand`
                            : `widget/basicTable/row`,
                    },
                    Iframe: ``,
                    // Anomaly: {
                    //   onLoad: `widget/basicTable/${this.$store.getters.project}/${chart.configuration}`,
                    //   onRowSelect: `widget/basicTable/row`,
                    // },
                    BarChart: `widget/chart/${this.$store.getters.project}/${todayFormatted}/${chart.configuration}`,
                    TextBigCard: `stellio/textBigCard/${this.$store.getters.project}/${chart.configuration}`,
                    LineChart: chart.subType?.includes("count-with-time")
                        ? `widget/chart/${this.$store.getters.project}/${todayFormatted}/${chart.configuration}`
                        : chart.subType?.includes("data-with-time")
                            ? `widget/chart2/${todayFormatted}/${chart.configuration}`
                            : ``,
                    MultiCards: chart.subType?.includes("stellio-type")
                        ? `stellio/basicTable/${this.$store.getters.project}/${chart.configuration}`
                        : ``,
                    RunSimulator: `widget/runSimulator/${chart.configuration}`,
                    Schedule: {
                        onLoad: `widget/attributeAsTable/${this.$store.getters.project}/${chart.configuration}`,
                        onRowSelect: ``,
                    },
                };
                return urlMap[chart.type] || "";
            },
            getGridStyle(cluster) {
                const colCount = cluster.length;
                return {
                    display: "grid",
                    gridTemplateColumns: `repeat(${colCount}, 1fr)`,
                    gap: "20px",
                };
            },
            getChartStyle(chart) {
                const fixedStyleString = chart.style
                    ? chart.style.replace(/'/g, '"')
                    : "{}";

                const existingStyle = fixedStyleString
                    ? JSON.parse(fixedStyleString)
                    : {};

                const newStyle = {
                    padding: "4px",
                    margin: "16px 0px",
                };

                const mergedStyle = { ...existingStyle, ...newStyle };

                return mergedStyle;
            },
            createClusters() {
                const clusterMap = {};
                this.sortedCharts.forEach((chart) => {
                    const row = chart.layout[0];
                    if (!clusterMap[row]) {
                        clusterMap[row] = [];
                    }

                    for (const row in clusterMap) {
                        clusterMap[row].sort((a, b) => a.layout[1] - b.layout[1]);
                    }

                    clusterMap[row].push(chart);
                });

                this.clusters = Object.values(clusterMap);
            },
            fnOnClick(eventData) {
                if (Array.isArray(this.clusters)) {
                    this.clusters.forEach((cluster) => {
                        cluster.forEach((chart) => {
                            //this.clusters[cIndex][index]["clickEvent"] = eventData;
                            chart["clickEvent"] = eventData;
                            //console.log(chart, cIndex, index);
                        });
                    });
                }
            },
            fnOnUpdate() {
                this.reloadComponents += 1;
            }
        },
    };
</script>
<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
    }
</style>
