import { axiosService } from "@/services/AxiosService.js";
class AlertService {

    constructor() {
      this.entityPath = "alerts";
    }

  save(alertObj) {
    let actionPath = `${this.entityPath}/save`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, alertObj);
  }

  get(alertId) {
    let actionPath = `${this.entityPath}/get/${alertId}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }

  list(project, role, stage) {
    let actionPath = `${this.entityPath}/list/${project}/${role}/${stage}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }

  find(project) {
    let actionPath = `${this.entityPath}/find/${project}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }

  delete(alertId) {
    const actionPath = `${this.entityPath}/del/${alertId}`;
    console.log(`Delete to: ${actionPath}`);

    return axiosService.doDelete(actionPath);
  }

}

export const alertService = new AlertService();
