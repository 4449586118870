<template>
  <div class="card">
    <Chart
      type="line"
      :data="chartData"
      :options="chartOptions"
      style="height: 500px"
    />
  </div>
</template>

<script>
import Chart from "primevue/chart";
import axios from "axios";

const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue("--text-color");
const textColorSecondary = documentStyle.getPropertyValue(
  "--text-color-secondary"
);
// const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

export default {
  components: {
    Chart,
  },
  props: {
    chartObject: Object,
    url: String,
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        this.chart = newValue;
        this.getData();
      },
    },
  },
  data() {
    return {
      chart: "",
      chartData: {},
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: "#f4f6f7",
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: "#f4f6f7",
            },
          },
        },
      },
    };
  },
  methods: {
    getData() {
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: response.data.conf.label,
                data: response.data.data,
                fill: false,
                borderColor: `#${response.data.conf.color}`,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
            ],
          };
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
