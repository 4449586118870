import axios from "axios";

class AuthService {
    //let _apiBase = undefined;

    constructor(baseApi) {
        this.apiBase = baseApi;
    }

    setBaseApi(baseApi) {
        this.apiBase = baseApi;
    }
    login(user, pass) {
        //var result = {
        //    sucess: false,
        //    message: "",
        //    token: undefined
        //};

        const data = {
            user: user,
            password: pass
        };
        //console.log("Auth - Login: email: " + data.user + ", Password: " + data.password);
        console.log(`Post to: ${this.apiBase}/auth/login`);

        return axios.post(`${this.apiBase}/auth/login`, data, { headers: { 'Content-Type': 'application/json' } });
            //.then((response) => {

            //    //alert(JSON.stringify(response.data));
            //    if (response !== undefined && response.token !== undefined) {
            //        result.token = response;
            //        result.sucess = true;
            //    }
            //    else {
            //        result.message = "Invalid Response. There is no token available.";
            //        result.sucess = false;
            //    }
            //    //authenticated ? onAuthenticatedCallback() : alert("non authenticated");
            //});
            //.catch((error) => {
            //    // handle error
            //    console.log("*** Error ****");
            //    console.log(`Error Message: ${error}`);
            //    result.sucess = false;
            //    result.message = error;
            //});
        //return result;
    }

    refreshToken(refresh_token) {
        const data = {
            rtoken: refresh_token
        };
        console.log(`Post to: ${this.apiBase}/auth/refresh_token`);
        //console.log(`refresh_token: ${refresh_token}`); 

        return axios.post(`${this.apiBase}/auth/refresh_token`, data, { headers: { 'Content-Type': 'application/json' } });
    }

    logout(refresh_token) {
        const data = {
            rtoken: refresh_token
        };
        console.log(`Auth - Logout - Post to: ${this.apiBase}/auth/logout`);
        //console.log(`Auth - Logout - refresh_token: ${refresh_token}`);
        //keycloakInstance.logout();
        //axios.post(`${apiBase}/auth/logout`, data, { headers: { 'Content-Type': 'application/json' } });
        axios.post(`${this.apiBase}/auth/logout`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(function (response) {
                //console.log(response);
                let data = response.data;
                return data;
            })
            .catch(function (error) {
                let message = "Error when trying to logout";
                if (error.code == "ECONNABORTED") {
                    message = error.message;
                }
                else {
                    console.log(error);
                }

                return {
                    "success": false,
                    "message": message
                };
            });
    }

    changePassword(data) {
        const apiPath = `${this.apiBase}/auth/change-password`;
        console.log("Auth - Change Password");
        console.log(`Post to: ${apiPath}`);

        return axios.post(`${apiPath}`, data, { headers: { 'Content-Type': 'application/json'} });
    }

    delete(user, pass) {
        const data = {
            user: user,
            password: pass
        };

        const delPath = `${this.apiBase}/auth/delete`;
        console.log(`Auth - Delete User: ${data.user}`);
        console.log(`Post to: ${delPath}`);
        
        return axios.delete(`${delPath}`, { data: data });
    }

    userinfo(access_token) {
        return axios.get(`${this.apiBase}/auth/userinfo`, { headers: { "Authorization": `Bearer ${access_token}` } });
    }

    register(newUser) {

        const regPath = `${this.apiBase}/auth/register`;
        console.log(`Auth - Register User: ${newUser.username}`);
        console.log(`Post to: ${regPath}`);

        return axios.put(`${regPath}`, newUser);
    }

    forgotPassword(data) {
        const apiPath = `${this.apiBase}/auth/forgot-password`;
        console.log(`Auth - Forgot Password - Post to: ${apiPath}`);

        //return axios.post(`${apiPath}`, { username: data });
        return axios.post(`${apiPath}`, data);
    }

}

/*
const UserName = function () {
    return keycloakInstance?.tokenParsed?.preferred_username;
}


const isLoggedIn = function () {
    return !!keycloakInstance.token;
}

const getToken = function () {
    return keycloakInstance?.token;
}

const doLogin = keycloakInstance.login;

const updateToken = function (successCallback) {
    keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);
}

const UserRoles = function(){
    console.log(keycloakInstance);
    //if (keycloakInstance?.resourceAccess === undefined) return undefined;
    //return keycloakInstance.resourceAccess.account.roles;
    if (keycloakInstance?.realmAccess === undefined) return undefined;
    return keycloakInstance.realmAccess.roles;
};
*/

export const auth = new AuthService("");

    //GetUserName: UserName,
    //GetUserRoles: UserRoles,
    //IsLoggedIn: isLoggedIn,
    //accesToken: getToken,
    //UpdateToken: updateToken,
