<template>
  <div>
    <PrimeButton
      :label="chartObject['name']"
      severity="info"
      @click="onClick"
    />
    <div>{{ response }}</div>
  </div>
</template>
<script>
import axios from "axios";
import PrimeButton from "primevue/button";

export default {
  props: {
    chartObject: Object,
    url: String,
  },
  components: {
    PrimeButton,
  },
  data() {
    return {
      label: "",
      response: "",
    };
  },
  methods: {
    onClick() {
      console.log("--------- BUTTON ----------");
      console.log(`${this.$apiBase}/${this.url}`);
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          console.log(response.data);
          this.response = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
