<template>
  <div>
    <iframe
      :src="url"
      frameborder="0"
      style="width: 100%; height: 600px"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    chartObject: Object,
  },
  data() {
    return {
      url: "",
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Iframe Value");
        console.log(newValue);
        let config = newValue.configuration;
        config = config.replace(/'/g, '"');
        this.url = JSON.parse(config)["url"];
      },
    },
  },
};
</script>

<style scoped>
/* You can add some styles here if needed */
</style>
