<template>
    <div role="status" v-if="isLoading">
        <loader-mini-component></loader-mini-component>
    </div>
    <form id="frmAlerts" name="frm-alerts" ref="frmAlerts">
        <div class="px-5 py-3 flex-auto text-left">
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 w-fit mx-auto my-2">
                <table class="table-widget">
                    <tbody>
                        <tr>
                            <!-- Alert Name input -->
                            <td class="p-2">
                                <span class="inline-block w-32 font-bold">Alert name:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block w-72">
                                    <div v-if="v$.entity.name.$error" class="text-red-600 text-sm">Please enter a Name for the alert.</div>
                                    <InputText type="text" v-model="entity.name" class="w-full"
                                               :invalid="v$.entity.name.$error" />
                                    <div :class="{ 'hidden': v$.entity.name.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.name.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- Stage input -->
                            <td class="p-2"><span class="inline-block w-32 font-bold">Stage:</span></td>
                            <td class="p-2">
                                <div class="inline-block w-72">
                                    <span v-if="v$.entity.phase.$error" class="text-red-600 text-sm">Please select a stage.</span>
                                    <Dropdown v-model="entity.phase" :options="stages" optionLabel="name" optionValue="id" placeholder="Please Select one"
                                              class="w-full" :invalid="v$.entity.phase.$error" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <!-- Role input -->
                            <td class="p-2">
                                <span class="inline-block w-32 font-bold">Role:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block w-72">
                                    <div v-if="v$.entity.role.$error" class="text-red-600 text-sm">Please select a role.</div>
                                    <Dropdown v-model="entity.role" :options="roles" optionLabel="name" optionValue="id" placeholder="Please Select one"
                                              class="w-full" :invalid="v$.entity.role.$error" />
                                </div>
                            </td>
                            <!-- Responsible input -->
                            <td class="p-2"><span class="inline-block w-32 font-bold">Responsible:</span></td>
                            <td class="p-2">
                                <div class="inline-block w-72">
                                    <div v-if="v$.entity.responsible.$error" class="text-red-600 text-sm">Please select a resposible.</div>
                                    <Dropdown v-model="entity.responsible" :options="users" optionLabel="name" optionValue="id" placeholder="Please Select one"
                                              class="w-full" :invalid="v$.entity.responsible.$error" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 w-fit mx-auto my-2">
                <table class="table-widget">
                    <tbody>
                        <tr>
                            <!-- Role input -->
                            <td class="p-2"><span class="inline-block w-32 font-bold">Severity:</span></td>
                            <td class="p-2">
                                <div class="inline-block w-72">
                                    <div v-if="v$.entity.severity.$error" class="text-red-600 text-sm">Please select a severity for the alert.</div>
                                    <Dropdown v-model="entity.severity" :options="severityList" optionLabel="name" optionValue="id" placeholder="Please Select one"
                                              class="w-full" :invalid="v$.entity.severity.$error" />
                                </div>
                            </td>
                            <!-- Role input -->
                            <td class="p-2" colspan="2" rowspan="2">
                                <div class="w-[26.5rem]">
                                    <div class="font-bold mb-1">Frequency:</div>
                                    <div v-if="v$.entity.frequency.$error" class="text-red-600 text-sm">Please select a frequency for the alert.</div>
                                    <div class="w-full bg-surface-200 p-1">
                                        <div class="m-1">
                                            <RadioButton v-model="entity.frequency" inputId="radEverytime" name="frequency" :value="fixedValues.AlertFrequency.ALWAYS.code" />
                                            <label for="radEverytime" class="ml-2">Raise an alert with each value found.</label>
                                        </div>
                                        <div class="m-1">
                                            <RadioButton v-model="entity.frequency" inputId="radOnlyOne" name="frequency" :value="fixedValues.AlertFrequency.KEEP_ONE_OPEN.code" />
                                            <label for="radOnlyOne" class="ml-2">Keep only one alert open.</label>
                                        </div>
                                        <div class="m-1">
                                            <RadioButton v-model="entity.frequency" inputId="radWait" name="frequency" :value="fixedValues.AlertFrequency.WAIT_UNTIL.code" />
                                            <label for="radWait" class="ml-2">Wait before raise the alert again:</label>
                                            <br />
                                            <div v-if="entity.frequency == fixedValues.AlertFrequency.WAIT_UNTIL.code">
                                                <div v-if="v$.entity.waitBeforeRaise.$error" class="text-red-600 text-sm">Please enter the minutes to wait before raise the alert.</div>
                                                <div>
                                                    <InputNumber v-model="entity.waitBeforeRaise" inputClass="w-24" :invalid="v$.entity.waitBeforeRaise.$error" />
                                                    <span class="inline ml-2">minutes</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-1">
                                            <RadioButton v-model="entity.frequency" inputId="radWait" name="frequency" :value="fixedValues.AlertFrequency.MAINTAINED.code" />
                                            <label for="radWait" class="ml-2">Raise if the conditions are maintained</label>
                                            <br />
                                            <div v-if="entity.frequency == fixedValues.AlertFrequency.MAINTAINED.code">
                                                <div v-if="v$.entity.waitBeforeRaise.$error" class="text-red-600 text-sm">Please enter the minutes maintained to raise the alert.</div>
                                                <div>
                                                    <span class="inline mr-2">for:</span>
                                                     <InputNumber v-model="entity.waitBeforeRaise" inputClass="w-24" :invalid="v$.entity.waitBeforeRaise.$error" />
                                                    <span class="inline ml-2">minutes</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-1">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="w-[26.5rem] mt-2">
                                    <div class="font-bold mb-1">Send Alerts by:</div>
                                    <div class="w-full bg-surface-200 p-1">
                                        <div class="m-1">
                                            <Checkbox v-model="entity.sendBy" inputId="chkEmail" name="send_options" :value="fixedValues.AlertSendType.EMAIL" />
                                            <label for="chkEmail" class="ml-2">Email</label>
                                        </div>
                                        <div class="m-1">
                                            <Checkbox v-model="entity.sendBy" inputId="chkTextPhone" name="send_options" :value="fixedValues.AlertSendType.PHONE_TEXT" />
                                            <label for="chkTextPhone" class="ml-2">Text Phone</label>
                                        </div>
                                        <div class="m-1">
                                            <Checkbox v-model="entity.sendBy" inputId="chkWhatsApp" name="send_options" :value="fixedValues.AlertSendType.WHATSAPP" />
                                            <label for="chkWhatsApp" class="ml-2">WhatsApp</label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <!-- Actions input -->
                            <td class="p-2"><span class="inline-block w-32 font-bold">Actions:</span></td>
                            <td class="p-2">
                                <div class="inline-block w-72">
                                    <div v-if="v$.entity.actions.$error" class="text-red-600 text-sm">Please enter the actions to be executed.</div>
                                    <!-- Actions input -->
                                    <Textarea v-model="entity.actions" class="w-full h-64"
                                              :invalid="v$.entity.actions.$error" />
                                    <!--class="form-control text-sm block w-full p-1 font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />-->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 w-fit mx-auto my-2">
                <div>
                    <div class="inline-block w-32 font-bold m-1">Source of data:</div>
                    <div class="inline-block w-72 m-1">
                        <div v-if="v$.entity.dataSource.$error" class="text-red-600 text-sm">Please select a source of data for the conditions.</div>
                        <Dropdown v-model="entity.dataSource" :options="datasources" optionLabel="name" optionValue="id" placeholder="Please Select one"
                                  class="w-full" :invalid="v$.entity.dataSource.$error" @change="fnChangeDatasource" />
                    </div>
                </div>
                <DataTable :value="entity.conditions" size="small" stripedRows>
                           <!-- scrollable scrollHeight="200px" -->
                    <template #header>
                        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                            <span class="text-xl text-900 font-bold">Conditions</span>
                            <Button icon="pi pi-plus" title="Add a new condition" class="ml-auto"
                                    @click="fnAddCondition" rounded size="small" v-if="fnHasValue(entity.dataSource)"/>
                        </div>
                    </template>
                    <template #empty>
                        No alerts found.
                    </template>
                    <Column field="andOr" header="" class="w-28">
                        <template #body="{ data, index }">
                            <Dropdown v-if="index > 0"
                                      v-model="data.andOr.value" :options="andOrlist" optionLabel="text" optionValue="value"
                                      placeholder="Please Select one" class="w-24" />
                        </template>
                    </Column>
                    <Column field="field" header="Field" class="w-60">
                        <template #body="{ data, index }">
                            <Dropdown v-model="data.field.value" :options="dataFields" optionLabel="name" optionValue="value"
                                      placeholder="Please Select one" class="w-full" @change="fnFieldChange($event, index)"
                            :invalid="data.field.isInvalid" />
                        </template>
                    </Column>
                    <Column field="condition" header="Condition" class="w-60">
                        <template #body="{ data, index }">
                            <Dropdown v-model="data.condition.value" :options="conditionList" optionLabel="text" optionValue="value"
                                      placeholder="Please Select one" class="w-full" @change="fnChangeCondition($event, index)"
                                      :invalid="data.condition.isInvalid"></Dropdown>
                            <!--:invalid="$v.entity.conditions.$each[index].condition.$error" />-->
                        </template>
                    </Column>
                    <Column field="threshold" header="Value(s)" class="w-52">
                        <template #body="{ data, index }">
                            <div v-if="data.condition.value < 7">
                                <InputNumber v-model="data.threshold.value" inputClass="w-28" placeholder="Value" mode="decimal" :maxFractionDigits="5"
                                :invalid="data.threshold.isInvalid" @blur="fnChangeThreshold($event, index)"/>
                            </div>
                            <div v-if="data.condition.value == 7 || data.condition.value == 8">
                                <InputNumber v-model="data.thresholdMin.value" inputClass="w-20 mr-2" placeholder="From" mode="decimal" :maxFractionDigits="5"
                                :invalid="data.thresholdMin.isInvalid" @blur="fnChangeMin($event, index)"/>
                                <InputNumber v-model="data.thresholdMax.value" inputClass="w-20" placeholder="To" mode="decimal" :maxFractionDigits="5"
                                :invalid="data.thresholdMax.isInvalid" @blur="fnChangeMax($event, index)"/>
                            </div>
                        </template>
                    </Column>
                    <Column :exportable="false" class="w-16">
                        <template #body="{ index }">
                            <div class="w-12">
                                <Button v-if="index > 0" icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                        @click="fnDeleteCondition($event, index)" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="pt-2 px-2 w-[56rem] mx-auto mt-2 flex flex-wrap">
                <div class="ml-auto">
                    <Button label="Back" title="Go to Alerts page" severity="secondary" class="mr-2"
                            @click="fnCancel" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
                    <Button v-if="isEditing" label="Delete" title="Delete Alert." severity="danger" class="mr-2" 
                            @click="fnOpenConfirmBox" icon="pi pi-times-circle" iconPos="right" />
                    <Button v-if="!isEditing" label="Add" title="Add a new Alert."
                            @click="fnSave" icon="pi pi-plus-circle" iconPos="right" />
                    <Button v-if="isEditing" label="Save" title="Save changes."
                            @click="fnSave" icon="pi pi-check-circle" iconPos="right" />
                </div>
            </div>
        </div>
    </form>
    <confirmation-component :msg="confirmObj.message"
                            :isConfirmationOpen="confirmObj.isOpen"
                            @close="fnCloseConfirmBox"
                            @ok="fnDelete"></confirmation-component>
</template>

<script>
    import LoaderMiniComponent from "@/components/LoaderMiniComponent.vue";
    import ConfirmationComponent from "@/components/ConfirmationComponent.vue";

    import RadioButton from 'primevue/radiobutton';
    import Checkbox from 'primevue/checkbox';
    import Button from 'primevue/button';
    import Textarea from 'primevue/textarea';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';
    import Dropdown from 'primevue/dropdown';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    import { ToastSeverity } from 'primevue/api';
    import { useConfirm } from "primevue/useconfirm";

    import { useVuelidate } from '@vuelidate/core'
    //import { helpers, decimal } from '@vuelidate/validators'
    import { required, requiredIf, integer, minValue } from '@vuelidate/validators'

    import { alertService } from "../services/AlertService.js";
    import { catalogService } from "../services/CatalogService.js";

    import Constants from "../utils/Constants.js";
    import { capitalizeFirstLetter, stringHasValue, numberIsValid } from "../utils/utils.js";

    export default {
        name: "AlertComponent",
        emits: ["onError", "onCancel", "onSave", "onAdd", "onDelete"],
        setup() {
            return { v$: useVuelidate() }
        },
        components: {
            LoaderMiniComponent,
            ConfirmationComponent,
            RadioButton, Checkbox,
            Textarea, InputText, InputNumber,
            Dropdown, Button,
            DataTable, Column
        },
        props: {
            alertId: String,
            isOpen: Boolean,
            presetAlert: Object
        },
        data() {
            return {
                isLoading: false,
                isEditing: false,
                users: [],
                roles: [
                    { id: Constants.Roles.FOREMAN, name: "Foreman" },
                    { id: Constants.Roles.HEALTH_SAFETY, name: "Health & Safety" },
                    { id: Constants.Roles.OPERATOR, name: "Operator" },
                    { id: Constants.Roles.PROCUREMENT, name: "Procurement" },
                    { id: Constants.Roles.SUPERVISOR, name: "Supervisor" },
                ],
                stages: [
                    { id: Constants.Stages.PLANNING, name: "Planning" },
                    { id: Constants.Stages.PREPARATORY, name: "Preparatory" },
                    { id: Constants.Stages.SHOTCRETING, name: "Shotcreting" },
                    { id: Constants.Stages.FINISHING, name: "Finishing" },
                    { id: Constants.Stages.POSTWORK, name: "Post - work Inspection" }
                ],
                severityList: [
                    { id: Constants.AlertSeverity.CRITICAL, name: "Critical" },
                    //{ id: Constants.AlertSeverity.HIGH, name: "High" },
                    { id: Constants.AlertSeverity.WARNING, name: "Warning" },
                    //{ id: Constants.AlertSeverity.LOW, name: "Low" },
                ],
                conditionList: Constants.ComparisionConditions,
                fixedValues: Constants,
                datasources: [],
                dataFields: [],
                entity: {
                    id: undefined,
                    role: "",
                    phase: "",
                    name: "",
                    dataSource: "",
                    actions: "",
                    responsible: "",
                    severity: "",
                    sendBy: [],
                    frequency: undefined,
                    waitBeforeRaise: undefined,
                    status: 0,
                    condition: 0,
                    conditions: []
                },
                confirmObj: {
                    message: "",
                    isOpen: false
                },
                andOrlist: [{ text: "AND", value: "and" }, { text: "OR", value: "or" }],
                confirm: undefined
            }
        },
        validations() {
            return {
                entity: {
                    name: { required },
                    role: { required },
                    phase: { required },
                    responsible: { required },
                    dataSource: { required },
                    actions: { required },
                    severity: { required },
                    frequency: { required },
                    waitBeforeRaise: { integer, minValue: minValue(1), requiredIf: requiredIf(this.fnRequiredIf) },
                    /*conditions: {
                        $each: helpers.forEach({
                            field: { required },
                            condition: { required }
                            threshold: { decimal, requiredIf: requiredIf(function () { return this.entity.condition < 7; }) },
                            thresholdMin: { decimal, requiredIf: requiredIf(function () { return this.entity.condition > 6; }) },
                            thresholdMax: { decimal, requiredIf: requiredIf(function () { return this.entity.condition > 6; }) }
                        })
                    }*/
                }
            }
        },
        watch: {
            isOpen: function (val) {
                console.log("Is Open changed - " + this.isOpen.toString());
                this.isEditing = false;
                if (val == true) {
                    
                    if (this.alertId != undefined && this.alertId.length > 0) {
                        console.log("Loading alert...");
                        //console.log(this.alertId);
                        this.fnLoad();
                        this.isEditing = true;
                    }
                    else if (this.presetAlert != undefined) {
                        //console.log(this.presetAlert);
                        this.fnSetEntity(this.presetAlert);
                    }
                    else {
                        console.log("Opening and loading Catalogs...");
                        this.fnLoadCatalogs();
                    }

                }
            }
        },
        created() {
            console.log("Alert Component: Created");
            this.confirm = useConfirm();
        },
        methods: {
            fnRequiredIf () { 
                return this.entity.frequency == Constants.AlertFrequency.WAIT_UNTIL.code
                    || this.entity.frequency == Constants.AlertFrequency.MAINTAINED.code;
            },
            fnChangeDatasource: function (event) {
                //console.log(event);
                let val = event.value;
                
                if (val != undefined){
                    this.fnUpdateDatasource(val);
                }
            },
            fnUpdateDatasource: function (val) {

                //console.log("fnUpdateDatasource: ", val);
                this.dataFields = [];

                if (val.length < 1) {
                    return;
                }
                
                //console.log("fnUpdateDatasource Datasources..");
                this.entity.conditions = [this.fnGetConditionUI()];
                
                this.datasources.forEach((item) => {
                    if (item.id == val) {
                        this.dataFields = item.fields;
                        return;
                    }
                });
            },
            fnSave: function () {
                // Do some stuff
                this.v$.$validate()
                    .then(result => {
                        //TODO: Validate inner conditions.

                        if (this.fnValidateConditions() && result) {

                            this.isLoading = true;
                            //alert("fnSave - Adding an Alert...");

                            const newAlert = this.fnGetEntity();

                            alertService.save(newAlert).then((data) => {
                                //console.log(data);
                                if (!this.isEditing) {

                                    newAlert.alertId = data.upserted_id;
                                    this.$emit('onAdd', newAlert);
                                }
                                else {
                                    this.$emit('onSave', newAlert);
                                }

                                this.isEditing = true;
                                this.isLoading = false;
                                this.$emit('onClose');
                                return true;
                            })
                            .catch(error => {
                                console.log(error);
                                this.$emit('onError', 'Error', 'An error occuerd when trying to save the alert!', ToastSeverity.ERROR);

                                this.isLoading = false;
                            });
                        }

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                return false;
            },
            fnLoadDataSources() {
                this.datasources = [];
                //TODO: Call
                return catalogService.datasources(this.$store.getters.project);
            },
            fnLoadUsers() {
                this.users = [];
                //TODO: Call
                return catalogService.users();
            },
            fnLoadCatalogs() {
                this.isLoading = true;

                this.fnLoadDataSources().then((data) => {
                    //console.log(data);
                    if (data != undefined && Array.isArray(data)) {
                        this.datasources = data;
                    }
                    this.isLoading = false;

                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$emit('onError', 'Error', 'Error when loading "data source" list...!!!', ToastSeverity.ERROR);
                });
                
                this.fnLoadUsers().then((data) => {
                    //console.log(data);

                    if (data != undefined && Array.isArray(data)) {
                        this.users = data;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$emit('onError', 'Error', 'Error when loading "user" list...!!!', ToastSeverity.ERROR);
                });
            },
            fnLoad() {
                //console.log(data);
                this.entity.id = this.alertId;
                this.isLoading = true;

                alertService.get(this.alertId)
                    .then((data) => {
                        //console.log(data);
                        if (data != undefined) {
                            this.fnSetEntity(data);
                            this.isEditing = true;
                        }
                        else {
                            this.$emit('onError', 'Error', 'Alert was NOT found', ToastSeverity.ERROR);
                        }
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$emit('onError', 'Error', 'Error when loading data...!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnDelete() {

                //this.entity.id = alertId;
                this.isLoading = true;

                alertService.delete(this.entity.id)
                    .then(() => {
                        //.then((data) => {
                        //console.log(data);
                        this.confirmObj.message = "";
                        this.confirmObj.isOpen = false;

                        this.$emit('onDelete', this.entity.id, this.entity.name);

                        this.fnCancel();
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$emit('onError', 'Error', 'An error occurred when trying to delete the alert!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnCancel() {
                this.$emit('onCancel');
            },
            fnGetEntity() {
                let obj = {
                    alertId: this.entity.id,
                    role: this.entity.role,
                    phase: this.entity.phase,
                    name: this.entity.name,
                    actions: this.entity.actions,
                    dataSource: this.entity.dataSource,
                    
                    responsible: this.entity.responsible,
                    sendBy: this.entity.sendBy,
                    severity: this.entity.severity,
                    frequency: this.entity.frequency,
                    waitBeforeRaise: this.entity.waitBeforeRaise,
                    projectId: this.$store.getters.project,
                    status: 0,
                    //conditions: this.entity.conditions,
                    conditions: [],
                    createdAt: this.entity.createdAt,
                    createdBy: this.entity.createdBy,
                    updatedAt: this.entity.updatedAt,
                    updatedBy: this.$store.getters.username,
                };
                this.entity.conditions.forEach((item) => {
                    obj.conditions.push({
                        condition: item.condition.value,
                        field: item.field.value,
                        threshold: item.threshold.value,
                        thresholdMin: item.thresholdMin.value,
                        thresholdMax: item.thresholdMax.value,
                        andOr: item.andOr.value
                    });
                });

                return obj;
            },
            fnSetEntity(data) {
                //console.log("fnSetEntity", data);
                this.entity.role = data.role;
                this.entity.phase = data.phase;
                this.entity.name = data.name;
                this.entity.actions = data.actions;

                this.entity.severity = data.severity;
                this.entity.frequency = data.frequency;
                this.entity.waitBeforeRaise = data.waitBeforeRaise;
                this.entity.sendBy = [];

                if (Array.isArray(data.sendBy))
                    this.entity.sendBy = data.sendBy;

                this.entity.status = data.status;
                this.entity.createdAt = data.createdAt;
                this.entity.createdBy = data.createdBy;
                this.entity.updatedAt = data.updatedAt;
                this.entity.updatedBy = data.updatedBy;

                this.fnLoadUsers().then((result) => {
                    //console.log(result);
                    if (result != undefined && Array.isArray(result)) {
                        this.users = result;
                    }
                    this.entity.responsible = data.responsible;
                })
                .catch(error => {
                    console.log(error);
                    this.$emit('onError', 'Error', 'Error when loading "user" list...!!!', ToastSeverity.ERROR);
                });
                
                this.fnLoadDataSources().then((result) => {
                    //console.log("fnLoadDataSources", result);
                    if (result != undefined && Array.isArray(result)) {
                        this.datasources = result;
                    }
                    
                    this.entity.dataSource = data.dataSource;
                    this.fnUpdateDatasource(data.dataSource);
                    
                    this.entity.conditions = [];
                    if (Array.isArray(data.conditions))
                    {
                        data.conditions.forEach((item) => {
                            this.entity.conditions.push({
                                condition: { value: item.condition, isInvalid: false },
                                field: { value: item.field, isInvalid: false },
                                threshold: { value: item.threshold, isInvalid: false },
                                thresholdMin: { value: item.thresholdMin, isInvalid: false },
                                thresholdMax: { value: item.thresholdMax, isInvalid: false },
                                andOr: { value: item.andOr, isInvalid: false }
                            });
                        });
                    }

                })
                .catch(error => {
                    console.log(error);
                    this.$emit('onError', 'Error', 'Error when loading "data source" list...!!!', ToastSeverity.ERROR);
                });
            },
            fnOpenConfirmBox: function () {
                this.confirmObj.message = "Do you really want to delete the alert: '" + this.entity.name + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnAddCondition() {
                if (this.entity.conditions == undefined) {
                    this.entity.conditions = [];
                }
                this.entity.conditions.push(this.fnGetConditionUI());
            },
            fnGetConditionUI() {
                return {
                    condition: { value: undefined, isInvalid: false },
                    field: { value: undefined, isInvalid: false },
                    threshold: { value: undefined, isInvalid: false },
                    thresholdMin: { value: undefined, isInvalid: false },
                    thresholdMax: { value: undefined, isInvalid: false },
                    andOr: { value: "and", isInvalid: false }
                };
            },
            fnDeleteCondition(event, index) {
                this.confirm.require({
                    target: event.currentTarget,
                    message: 'Do you want to delete this condition?',
                    icon: 'pi pi-info-circle',
                    rejectClass: 'p-1 text-sm text-white dark:text-surface-900 bg-surface-500 dark:bg-surface-400 border border-surface-500 dark:border-surface-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300 focus:ring-surface-400/50 dark:focus:ring-surface-300/50',
                    acceptClass: 'p-1 text-sm text-white dark:text-surface-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300 focus:ring-red-400/50 dark:focus:ring-red-300/50',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Delete',
                    position: "topright",
                    accept: () => {
                        console.log("Delete item: " + index.toString());
                        this.entity.conditions.splice(index, 1);
                    },
                    reject: () => {
                        console.log("Cancel... Do nothing.");
                    }
                });
            },
            fnFieldChange: function (event, index) {
                let val = event.value;
                //console.log(event, index, val);
                this.entity.conditions[index].field.isInvalid = !stringHasValue(val);
            },
            fnChangeCondition: function (event, index) {
                let val = event.value;
                
                if (val == 9 || val == 10 ){
                    this.entity.conditions[index].threshold.value = val == 9 ? 1 : 0; //Is True
                }
                this.entity.conditions[index].condition.isInvalid = !stringHasValue(val == undefined ? "" : val.toString());
                console.log(val, !stringHasValue(val));
            },
            fnChangeThreshold: function (event, index) {
                let val = event.value;
                console.log(event, index, val);
                this.entity.conditions[index].threshold.isInvalid = !numberIsValid(val);
            },
            fnChangeMin: function (event, index) {
                let val = event.value;
                console.log(event, index, val);
                this.entity.conditions[index].thresholdMin.isInvalid = !numberIsValid(val);
            },
            fnChangeMax: function (event, index) {
                let val = event.value;
                console.log(event, index, val);
                this.entity.conditions[index].thresholdMax.isInvalid = !numberIsValid(val);
            },
            fnValidateConditions: function () {
                let isValid = true;
                this.entity.conditions.forEach(function (item, index, arr) {
                    // Validate Field
                    if (!stringHasValue(item.field.value)) {
                        isValid = false;
                        arr[index].field.isInvalid = true;
                    }

                    // Validate Conditions
                    if (!stringHasValue(item.condition.value == undefined ? "" : item.condition.value.toString())) {
                        isValid = false;
                        arr[index].condition.isInvalid = true;
                    }

                    if (item.condition.value == 7 || item.condition.value == 8) {
                        // Validate thresholdMin
                        if (!numberIsValid(item.thresholdMin.value)) {
                            isValid = false;
                            arr[index].thresholdMin.isInvalid = true;
                        }
                        // Validate thresholdMax
                        if (!numberIsValid(item.thresholdMax.value)) {
                            isValid = false;
                            arr[index].thresholdMax.isInvalid = true;
                        }
                    }
                    else {
                        // Validate threshold
                        if (!numberIsValid(item.threshold.value)) {
                            isValid = false;
                            arr[index].threshold.isInvalid = true;
                        }
                    }
                });

                return isValid;
            },
            fnHasValue(val) {
                return stringHasValue(val);
            }
        }
    };
</script>
<style scoped>
    table {
        text-align: left;
    }

    th {
        padding: 0.75rem 0.75rem 0.75rem 0.25rem;
    }

    td {
        padding: 0.25rem;
    }
</style>

