<template>
  <div class="card">
    <Chart type="bar" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import Chart from "primevue/chart";
import axios from "axios";

const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue("--text-color");
const textColorSecondary = documentStyle.getPropertyValue(
  "--text-color-secondary"
);
// const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

export default {
  components: {
    Chart,
  },
  props: {
    chartObject: Object,
    url: String,
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Value");
        console.log(newValue);
        this.chart = newValue;
        this.getData();
      },
    },
  },
  data() {
    return {
      chart: "",
      chartData: {},
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: "#ccc",
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: "#ccc",
            },
          },
        },
      },
    };
  },
  methods: {
    getData() {
      console.log("----- BAR -----");
      console.log(`${this.$apiBase}/${this.url}`);
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          console.log("--------- BACKGROUND COLOR CHECK ----------");
          console.log(response.data);
          console.log(response.data.conf);
          console.log(response.data.conf.backgroundcolor);
          console.log(response.data.conf.bordercolor);

          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: response.data.conf.label,
                data: response.data.data,
                backgroundColor: [
                  "rgba(249, 115, 22, 0.2)",
                  "rgba(6, 182, 212, 0.2)",
                  "rgb(107, 114, 128, 0.2)",
                  "rgba(139, 92, 246 0.2)",
                  "rgba(249, 115, 22, 0.2)",
                  "rgba(6, 182, 212, 0.2)",
                ],
                borderWidth: 1,
                borderColor: [
                  "rgb(249, 115, 22)",
                  "rgb(6, 182, 212)",
                  "rgb(107, 114, 128)",
                  "rgb(139, 92, 246)",
                  "rgb(249, 115, 22)",
                  "rgb(6, 182, 212)",
                ],
              },
            ],
          };
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
