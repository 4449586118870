<template>
    <div class="flex align-middle px-5">
        <div class="pt-2 px-2 w-[56rem] mx-auto mt-2 flex flex-wrap">
            <h2 class="text-4xl leading-tight text-center m-auto text-bold">Incident Details</h2>
        </div>
    </div>
    <incident-details-component :entityId="entityId"
                                :isOpen="isOpen"
                                @onCancel="fnCancel"
                                @onSave="fnSave"
                                @onAdd="fnAdd"
                                @onDelete="fnDelete"
                                @onSetName="fnSetName"
                                @onError="fnShowToast"></incident-details-component>
    <loader-component :isLoading="isLoading" type="primary"></loader-component>
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import IncidentDetailsComponent from "@/components/Incident/IncidentDetailsComponent";
    
    import { ToastSeverity } from 'primevue/api';

    export default {
        name: "IncidentDetailsPage",
        components: {
            IncidentDetailsComponent,
            LoaderComponent
        },
        data() {
            return {
                isLoading: false,
                entityId: undefined,
                entityName: undefined,
                isOpen: false
            };
        },
        created() {
            console.log("Incident Details Page: Created");

            this.entityId = this.$route.params.entityId;
            console.log("IncidentId: " + this.$route.params.entityId);

            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Incident Details Page...");
        },
        methods: {
            fnLoad: function () {
                console.log("Incident  Details: loading - " + this.entityId);
                this.isLoading = true;
                this.isOpen = true;
                this.isLoading = false;
            },
            fnAdd(entitySaved) {
                this.fnShowToast('Save Success', 'The Incident  "' + entitySaved.batch + '" was inserted!', ToastSeverity.SUCCESS);
            },
            fnSave(entityNew) {
                this.fnShowToast('Save Success', 'The Incident  "' + entityNew.batch + '" was updated!', ToastSeverity.SUCCESS);
            },
            fnDelete(entityName) {

                this.fnShowToast('Delete Success', 'The Incident  "' + entityName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + alertName + '" was deleted!');
                this.fnCancel();
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCancel() {
                this.$router.push({ name: "incident-management" });
            },
            fnSetName(name){
                this.entityName = name;
            }
        }
    };
 
</script>
