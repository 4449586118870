<template>
    <PrimeDialog v-model:visible="visible" modal header="Asset Details" @hide="fnClose"
                 :draggable="false" :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div v-if="!loading">
        <div class="w-full mb-2">
            <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400">
                <div class="w-full flex flex-row">
                    <div class="m-0">
                        <div class="w-full flex flex-row">
                            <div class="w-32 text-indigo-800">ID:</div>
                            <div>{{ entity.assetId }}</div>
                        </div>
                        <div class="w-full flex flex-row">
                            <div class="w-32 text-indigo-800">Asset:</div>
                            <div>{{ entity.name }}</div>
                        </div>
                        <div class="w-full flex flex-row">
                            <div class="w-32 text-indigo-800">Asset Type:</div>
                            <div>{{ fnCapText(entity.assetType) }}</div>
                        </div>
                    </div>
                    <Button v-if="!entity.disposed" label="Dispose" title="Dispose Asset"
                            class="ml-auto max-h-10" severity="warning"
                            @click="fnDisposeOpen" rounded size="small" />
                </div>
            </div>
        </div>
        <div class="w-full mb-2">
            <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400">
                <table class="table-auto w-full">
                    <thead class="text-indigo-800">
                        <tr>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1" colspan="4">Hours</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1" rowspan="2">Failure <br />Probabilty</th>
                        </tr>
                        <tr>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1">Maximum</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1">Operational</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1">Remaining</th>
                            <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1">Predicted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeSpan) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.operationalTime) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeLeft) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeEstimated) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatPercentaje(entity.failureProbability) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="w-full mb-2" v-if="entity.disposed">
            <div class="w-full border-2 p-2 shadow-md table-widget rounded border-indigo-400">
                <div class="w-full flex flex-row">
                    <div class="w-32 text-indigo-800">Disposed on:</div>
                    <div class="w-32">{{ fnFormatDate(entity.dispose.disposeDate) }}</div>
                    <!--</div>
                <div class="w-full flex flex-row">-->
                    <div class="w-32 text-indigo-800">Disposed type:</div>
                    <div class="w-32">{{ fnCapText(entity.dispose.disposeType) }}</div>
                </div>
                <div class="w-full flex flex-row">
                    <div class="w-32 text-indigo-800">Reason:</div>
                    <div>{{ fnCapText(entity.dispose.reason) }}</div>
                </div>
            </div>
        </div>
        <div class="w-full mb-2 flex flex-row">
            <div class="w-full">
                <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
                    <div class="flex align-middle">
                        <h3 class="text-indigo-600 text-center m-auto text-bold"
                            style="text-transform: uppercase;">
                            Worked hours
                        </h3>
                        <Button icon="pi pi-plus" title="Add worked hours" class="ml-auto"
                                @click="fnWorkedTimeOpen" rounded size="small" />
                    </div>
                    <DataTable :value="entity.workLog" size="small" scrollHeight="18rem" sortField="workDate" :sortOrder="-1"
                               paginator :rows="8" :rowsPerPageOptions="[8, 12, 24, 48]">
                        <Column field="workDate" header="Date" sortable>
                            <template #body="{ data }">
                                {{ fnFormatTextDate(data.workDate) }}
                            </template>
                        </Column>
                        <Column field="workHours" header="Worked hours" sortable></Column>
                        <Column :exportable="false" class="w-16">
                            <template #body="{ data }">
                                <div class="w-12">
                                    <Button icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                            @click="fnDeleteWork($event, data)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
        </div>
        <div v-if="loading">
            <loader-mini-component></loader-mini-component>
        </div>
        <template #footer>
            <Button label="Back" title="Close window" severity="secondary" class="mr-2"
                    @click="fnClose" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
        </template>

    </PrimeDialog>
    <asset-dispose-component :assetId="assetId" :assetName="assetName" :isOpen="openDisposeWindow"
                             @onError="fnShowToast" @onDispose="fnDisposeAsset" @onClose="fnDisposeClose" />
    <asset-maintenance-component :assetId="assetId" :assetName="assetName" :isOpen="openMaintWindow"
                             @onError="fnShowToast" @onAddMaint="fnMaintenanceAdd" @onClose="fnMaintenanceClose" />
    <asset-work-component :assetId="assetId" :assetName="assetName" :isOpen="openWorkWindow"
                             @onError="fnShowToast" @onAddWork="fnWorkedTimeAdd" @onClose="fnWorkedTimeClose" />
</template>

<script>
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    
    import { ToastSeverity } from 'primevue/api';
    import { useConfirm } from "primevue/useconfirm";

    import Constants from "@/utils/Constants.js";
    import { assetService } from "@/services/AssetService.js";
    import { formatDate, formatNumber, formatPercentage } from "@/utils/utils.js";
    import { capitalizeFirstLetter } from "@/utils/utils.js";

    import LoaderMiniComponent from '@/components/LoaderMiniComponent'

    import AssetDisposeComponent from '@/components/Assets/AssetDisposeComponent'
    import AssetMaintenanceComponent from '@/components/Assets/AssetMaintenanceComponent'
    import AssetWorkComponent from '@/components/Assets/AssetWorkComponent'

    export default {
        name: "AssetDetailsComponent",
        emits: ["onError", "onClose", "onDispose", "onWorkAdded", "onWorkRemoved"],
        components: {
            Button,
            PrimeDialog,
            DataTable, Column,
            LoaderMiniComponent,
            AssetDisposeComponent,
            AssetMaintenanceComponent,
            AssetWorkComponent
        },
        props: {
            assetId: String,
            assetName: String,
            isOpen: Boolean
        },
        data() {
            return {
                loading: false,
                entity: {
                    assetId: undefined,
                    disposed: false,
                    reason: "",
                    user: undefined,
                    dispose: {
                        reason: "",
                        disposeDate: undefined,
                        disposeType: undefined
                    },
                    workLog: []
                },
                confirmObj: {
                    message: "",
                    isOpen: false
                },
                disposeStatus: [
                    { name: 'Failed', code: 'failed' },
                    { name: 'End of lifetime', code: 'lifetime-end' }
                ],
                //textHeader: "",
                disposeTypes: Constants.Asset.DISPOSE_TYPE,
                visible: false,
                openDisposeWindow: false,
                openWorkWindow: false,
                openMaintWindow: false
            }
        },
        created() {
            console.log("Asset Details Component: Created");
            this.confirm = useConfirm();
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {
                    console.log("Dispose Asset Component Opened!");
                    this.entity.assetId = this.assetId;
                    //this.textHeader = "Dispose Asset: " + this.assetName;
                    this.fnLoadAsset();
                    this.visible = this.isOpen;
                }
            }
        },
        methods: {
            fnLoadAsset() {
                this.loading = true;
                assetService.get(this.entity.assetId)
                    .then((data) => {
                        //console.log(data);
                        if (Array.isArray(data.workLog))
                        {
                            data.workLog.forEach((item) => { item.workDate = new Date(item.workDate); })
                            //data.workLog.sort((a, b) => Number(a.workDate ) - Number(b.workDate ));
                        }
                        this.entity = data;
                        
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('Error', 'An error occurred when trying to dispose the Asset: "' + this.assetName + '"!!!', ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnClose() {
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    assetId: undefined,
                    disposed: false,
                    reason: "",
                    user: undefined,
                    dispose: {
                        reason: "",
                        disposeDate: undefined,
                        disposeType: undefined
                    },
                    workLog: []
                };
                this.textHeader = "";
            },
            fnMaintenanceOpen() {
                this.openMaintWindow = true;
            },
            fnMaintenanceClose() {
                this.openMaintWindow = false;
            },
            fnMaintenanceAdd(result) {
                //console.log(result);
                this.fnShowToast('Maintenace Success', 'The maintenance record have been added to the asset "' + this.assetName + '".', ToastSeverity.SUCCESS);
                if (this.entity.maintenanceLog != undefined && !Array.isArray(this.entity.maintenanceLog))
                    this.entity.maintenanceLog = [];

                this.entity.maintenanceLog.push(result); 

                //alert("Maintenance Add, change Asset status.");
                this.fnMaintenanceClose();
            },
            fnWorkedTimeOpen() {
                this.openWorkWindow = true;
            },
            fnWorkedTimeClose() {
                console.log("fnWorkedTimeClose.");
                this.openWorkWindow = false;
            },
            fnWorkedTimeAdd(result) {
                
                this.fnShowToast('Add Work Success', 'The working hours have been added to the asset "' + this.assetName + '".', ToastSeverity.SUCCESS);
                
                if (this.entity["workLog"] != undefined && !Array.isArray(this.entity["workLog"]))
                    this.entity["workLog"] = [];

                this.entity.workLog.push(result); 

                this.entity.operationalTime += result.workHours;
                this.entity.lifetimeLeft -= result.workHours;
                this.entity.lifetimeEstimated -= result.workHours;
                this.entity.failureProbability = this.entity.operationalTime / this.entity.lifetimeSpan;

                console.log("Add work to Asset.", result);
                this.$emit("onWorkAdded", result);

                this.fnWorkedTimeClose();
                
            },
            fnDisposeOpen() {
                this.openDisposeWindow = true;
            },
            fnDisposeClose() {
                this.openDisposeWindow = false;
            },
            fnDisposeAsset(dispose) {
                //console.log(dispose);
                this.fnShowToast('Dispose Success', 'The asset "' + this.assetName + '" was disposed!', ToastSeverity.WARN);
                
                this.entity.disposed = true;
                this.entity.dispose = dispose;
                
                //alert("Dispose Asset actions.");
                this.$emit("onDispose", dispose, this.alertName);
                this.fnDisposeClose();
            },
            fnDeleteWork(event, item) {
                this.confirm.require({
                    target: event.currentTarget,
                    message: 'Do you want to delete this register?',
                    icon: 'pi pi-info-circle',
                    rejectClass: 'p-1 text-sm text-white dark:text-surface-900 bg-surface-500 dark:bg-surface-400 border border-surface-500 dark:border-surface-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300 focus:ring-surface-400/50 dark:focus:ring-surface-300/50',
                    acceptClass: 'p-1 text-sm text-white dark:text-surface-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300 focus:ring-red-400/50 dark:focus:ring-red-300/50',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Delete',
                    position: "topright",
                    accept: () => {
                        this.loading = true;

                        let workEntity = {
                            assetId: this.entity.assetId,
                            workId: item.workId,
                            workHours: item.workHours,
                            user: item.user
                        };

                        console.log("Delete item", workEntity);

                        assetService.removeWork(workEntity)
                            .then((data) => {
                                this.fnShowToast('Remove Work Success', 'The working hours have been removed from the asset "' + this.assetName + '".', ToastSeverity.WARN);
                                console.log("Work removed from Asset.", data);
                                 
                                // Update component with values. 
                                this.entity.operationalTime -= workEntity.workHours;
                                this.entity.lifetimeLeft += workEntity.workHours;
                                this.entity.lifetimeEstimated += workEntity.workHours;
                                this.entity.failureProbability = this.entity.operationalTime / this.entity.lifetimeSpan;

                                const index = this.entity.workLog.indexOf(item);
                                if (index > -1) { // only splice array when item is found
                                    this.entity.workLog.splice(index, 1);
                                }
                                console.log("index ", index);
                                this.$emit('onWorkRemoved', item);
                                this.loading = false;
                            })
                            .catch((error) => {
                                console.log(error);
                                this.$emit('onError', 'Unexpected Error', 'An error occurred when trying to work assigned!!!', ToastSeverity.ERROR);
                                this.loading = false;
                            });
                    },
                    reject: () => {
                        console.log("Cancel... Do nothing.");
                    }
                });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatPercentaje(number) {
                return formatPercentage(number);
            },
            fnFormatDate(dateValue) {
                return formatDate(dateValue);
            },
            fnFormatTextDate(textValue) {
                return formatDate(new Date(textValue));
            }
        }
    };
</script>
<style scoped>
</style>

