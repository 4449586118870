import { axiosService } from "@/services/AxiosService.js";

class PresetAlertService {

    constructor() {
        this.entityPath = "alerts-preset";
    }

    get(entityId)
    {
      let actionPath = `${this.entityPath}/get/${entityId}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

    list(role, stage) {

      let actionPath = `${this.entityPath}/list/${role}/${stage}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

    find() {
      let actionPath = `${this.entityPath}/find`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }
}

export const presetAlertService = new PresetAlertService();
