const isEmailValid = (email) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!email.match(validRegex)) {
    return false;
  }
  return true;
};

const isPasswordValid = (password) => {
  let error_list = [];
  if (password.length < 8) {
    error_list.push("Your password must be at least 8 characters");
  }
  if (password.search(/[a-z]/i) < 0) {
    error_list.push(
      "Your password must contain at least one lower case letter."
    );
  }
  if (password.search(/[A-Z]/i) < 0) {
    error_list.push("Your password must contain at upper case letter.");
  }
  if (password.search(/[0-9]/) < 0) {
    error_list.push("Your password must contain at least one digit.");
  }
  if (error_list.length > 0) {
    return { status: false, msg: error_list.join("\n") };
  }
  return { status: true };
};

const jwtDecode = (t) => {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token)
};

const parseJwt = (token) => {

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const capitalizeFirstLetter = (text) => {
    let result = "";
    if (text != undefined && text.length > 0)
        result = text.charAt(0).toUpperCase() + text.slice(1);
    return result;
}

const formatNumber = (number) => {
    let text = new Intl.NumberFormat().format(number);
    return text;
}

const formatPercentage = (number) => {
    var option = {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    var formatter = new Intl.NumberFormat("en-GB", option);
    var text = formatter.format(number);

    return text;
}

const formatDate = (dateValue) => {
    let result = new Date(2000, 0, 1);
  if (dateValue != undefined && dateValue instanceof Date) {
    result = dateValue;
    }

  return result.toLocaleString('en-GB', {
        day: "2-digit", month: "short", year: "numeric"
    });
    //return result.toLocaleString('en-GB', { dateStyle: "short" });
}

const formatDateTime = (dateValue) => {
  let today = new Date(2000, 0, 1);
  if (dateValue != undefined && dateValue instanceof Date) {
    today = dateValue;
  } 
    //console.log(dateValue.substring(0, 10));
    let yy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hh = today.getHours();
    let mi = today.getMinutes();
    let ss = today.getSeconds();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    if (hh < 10) hh = '0' + hh;
    if (mi < 10) mi = '0' + mi;
    if (ss < 10) ss = '0' + ss;

  //return `${dd}/${mm}/${yy} ${hh}:${mi}:${ss}`;
  return `${yy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
}

const dateTimeToString = (dateValue) => {
  let today = new Date(2000, 0, 1);
  if (dateValue != undefined && dateValue instanceof Date) {
    today = dateValue;
  }
  //console.log(dateValue.substring(0, 10));
  let yy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hh = today.getHours();
  let mi = today.getMinutes();
  let ss = today.getSeconds();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  if (hh < 10) hh = '0' + hh;
  if (mi < 10) mi = '0' + mi;
  if (ss < 10) ss = '0' + ss;

  //return `${dd}/${mm}/${yy} ${hh}:${mi}:${ss}`;
  return `${yy}-${mm}-${dd}T${hh}:${mi}:${ss}`;
}
const formatStringDateTime = (dateString) => {
  let result = dateString;
  //console.log(date);
  if (stringHasValue(dateString)) {
    if (dateString.indexOf("T") > 0) {
      result = dateString.replace("T", " ");
    }
    let pointIndex = dateString.indexOf(".");
    if (pointIndex > 0) {
      result = result.substring(0, pointIndex);
    }
  }
  return result;
};

const formatStringDate = (dateString) => {
  let result = dateString;
  //console.log(date);
  if (stringHasValue(dateString)) {
    let tIndex = dateString.indexOf("T");
    if (tIndex > 0) {
      result = dateString.substring(0, tIndex);
    }
  }
  return result;
};

const stringHasValue = (val) => {
    return val != undefined && typeof val == 'string' && val.trim().length > 0;
}

const arrayHasValue = (arr) => {
  return arr != undefined && Array.isArray(arr) && arr.length > 0;
}

const numberIsValid = (val) => {
  let result = true;

  if (typeof val == 'string') {
    result = !isNaN(val) && !isNaN(parseFloat(val))
  }
  else if (typeof val != 'number') {
    result = false;
  }

  return result;
}

export {
  isEmailValid,
  isPasswordValid,
  jwtDecode,
  parseJwt,
  capitalizeFirstLetter,
  stringHasValue,
  arrayHasValue,
  numberIsValid,
  formatNumber,
  formatPercentage,
  formatDate,
  formatDateTime,
  formatStringDateTime,
  formatStringDate,
  dateTimeToString
};
