<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Preset Alert Details</h2>
        <!--<Button type="button" label="ALERT" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Alert" @click="fnAddNew"></Button>-->
    </div>
    <alert-component :isOpen="isOpen"
                     @onCancel="fnBack"
                     @onSave="fnOnSave"
                     @onAdd="fnOnAdd"
                     @onDelete="fnOnDelete"
                     @onError="fnShowToast"
                     :presetAlert="entity"></alert-component>

    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import AlertComponent from "@/components/AlertComponent";
    
    import { ToastSeverity } from 'primevue/api';
    import { presetAlertService } from "../services/PresetAlertService.js";

    export default {
        name: "PresetDetailsPage",
        components: {
            AlertComponent,
            LoaderComponent
        },
        data() {
            return {
                presetId: undefined,
                isLoading: false,
                isOpen: false,
                entity: undefined,
                returnTo: "preset-alert"
            };
        },
        created() {
            console.log("Preset Alert Details: Created");
            
            
            this.presetId = this.$route.params.presetId;
            console.log("presetId: " + this.$route.params.presetId);

            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Preset Alert Manager Page...");
        },
        methods: {
            fnLoad: function () {
                console.log("Alert Details: loading - " + this.presetId);
                this.isLoading = true;
                this.entity = undefined;
                
                presetAlertService.get(this.presetId)
                    .then((data) => {

                        if (data != undefined) {
                            this.entity = this.fnGetAlert(data);
                            console.log(this.entity);
                            this.isOpen = true;
                        }
                        else {
                            this.fnShowToast('Error', `Preconfigured Alert '${this.presetId}' was NOT found.`, ToastSeverity.ERROR);
                        }
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fnShowToast('Error', `Error when loading the Preconfigured Alert '${this.presetId}'.`, ToastSeverity.ERROR);
                        //alert('Error when loading data...!!!');
                        this.isLoading = false;
                    });
            },
            fnOnAdd(alertSaved) {
                this.fnShowToast('Save Success', 'The alert "' + alertSaved.name + '" was inserted!', ToastSeverity.SUCCESS);
                this.returnTo = "alert-manager";
            },
            fnOnSave(alertNew) {
                this.fnShowToast('Save Success', 'The alert "' + alertNew.name + '" was updated!', ToastSeverity.SUCCESS);
                this.returnTo = "alert-manager";
            },
            fnOnDelete(alertName) {
                this.fnShowToast('Delete Success', 'The alert "' + alertName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + alertName + '" was deleted!');
                this.fnCancel();
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnBack() {
                this.$router.push({ name: this.returnTo });
            },
            fnGetAlert: function (entity) {

                //console.log(entity);
                return {
                    alertId: undefined,
                    role: entity.role,
                    phase: entity.phase,
                    name: entity.name,
                    conditions: entity.conditions,
                    actions: entity.actions,
                    dataSource: entity.datasource,
                    responsible: undefined,
                    sendBy: entity.sendBy,
                    severity: entity.severity,
                    frequency: entity.frequency,
                    waitBeforeRaise: entity.waitBeforeRaise,
                    updatedBy: this.$store.getters.username,
                    createdBy: this.$store.getters.username,
                    projectId: this.$store.getters.project
                };
            }
        }
    };

</script>
