<template>
    <div class="flex align-middle px-5 mb-2">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Project Information</h2>
            <Button label="Delete Project" title="Delete this Project" severity="danger" class="ml-auto"
                    @click="fnOpenConfirmationBox" icon="pi pi-times-circle" iconPos="right" />
    </div>
    <div v-if="this.temp" class="flex justify-between px-5 mb-2">
        <div class="bg-gradient-to-r from-purple-100 to-blue-100 pt-2 pb-4 px-6 rounded-lg flex flex-col justify-center">
            <div class="text-xs text-right pb-1">{{ lastUpdate.toLocaleString() }}</div>
            <div class="grid grid-cols-2 gap-4">
                <div class="my-1">
                    <table>
                        <tr>
                            <td><strong>Place</strong></td>
                            <td>:</td>
                            <td>
                                <div class="ml-2">{{ place }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Humidity</strong></td>
                            <td>:</td>
                            <td>
                                <div class="ml-2">{{ humidity }} %</div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="mx-4 my-1">
                    <table>
                        <tr>
                            <td><strong>Wind Speed</strong></td>
                            <td>:</td>
                            <td>
                                <div class="ml-2">{{ windSpeed }} m/s</div>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Atm. Pressure</strong></td>
                            <td>:</td>
                            <td>
                                <div class="ml-2">{{ pressure }} hPa</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="weather-widget bg-gradient-to-r from-green-100 to-yellow-100 pt-2 pb-6 px-6 rounded-lg">
            <div class="text-xs block text-right pb-1 pt-2">{{ lastUpdate.toLocaleString() }}</div>
            <div class="flex items-center">
                <div class="weather-icon mr-4">
                    <img :src="weatherIcon" alt="Weather icon" class="w-16 h-16" />
                </div>
                <div class="divider border-r border-gray-300 h-12 mx-4"></div>
                <div class="weather-info">
                    <div class="weather-temp text-4xl font-bold">{{ temp }}°C</div>
                    <div class="weather-description text-lg">
                        {{ weatherDescription }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-5 flex-auto">
        <dynamic-widget-component tab="generic-info"></dynamic-widget-component>
    </div>
    <ConfirmationBoxComponent msg="Are you sure you want to delete the project."
                              :isConfirmationBoxOpen="isConfirmationBoxOpen"
                              @close="fnCloseConfirmationBox"
                              @ok="fnRemoveProject"></ConfirmationBoxComponent>

</template>

<script>
import Button from 'primevue/button';

import ConfirmationBoxComponent from "@/components/ConfirmationBoxComponent";
import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";

import axios from "axios";

import { catalogService } from "@/services/CatalogService.js";


export default {
    name: "ProjectGenericInfo",
  components: {
      ConfirmationBoxComponent,
      DynamicWidgetComponent,
      Button
  },
  data() {
    return {
      temp: "",
      weatherIcon: "",
      weatherDescription: "",
      place: "",
      humidity: "",
      windSpeed: "",
        pressure: "",
        lastUpdate: "",
        isConfirmationBoxOpen: false
    };
  },
  mounted() {
    console.log("GENERIC INFO - MOUNTED");
      this.getProjectData();
      this.$logService.save(this.$options.name);
  },
  methods: {
    getProjectData() {
      axios
        .get(`${this.$apiBase}/catalogs/project/${this.$store.getters.project}`)
        .then((response) => {
          const weatherData = response.data.weather;
          this.temp = weatherData.main.temp;
          this.weatherDescription = weatherData.weather[0].description;
          this.weatherIcon = `https://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`;
          this.place = `${weatherData.name}, ${weatherData.sys.country}`;
          this.humidity = weatherData.main.humidity;
          this.windSpeed = weatherData.wind.speed;
            this.pressure = weatherData.main.pressure;
            this.lastUpdate = new Date(weatherData.dt*1000);
            
            console.log("weatherData", weatherData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      fnRemoveProject() {
          catalogService.deleteProject(this.$store.getters.project).then((data) => {
              console.log(data);
              console.log("Project Delete - ID: ", this.$store.getters.project);
              this.fnCloseConfirmationBox();
              this.$emitter.emit("deleteProject");

          }).catch(error => {
              console.log(error);
          });
      },
      fnCloseConfirmationBox() {
          this.isConfirmationBoxOpen = false;
      },
      fnOpenConfirmationBox() {
          this.isConfirmationBoxOpen = true;
      },
  },
};
</script>
