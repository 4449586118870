import { axiosService } from "@/services/AxiosService.js";

class IncidentService {

    constructor() {
      this.subPath = "incident";
    }

    save(entity) {
        let actionPath = `${this.subPath}/save`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

  close(entity) {
    let actionPath = `${this.subPath}/close`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, entity);
  }

  assignConcrete(assignIncident) {
    let actionPath = `${this.subPath}/concrete/assign`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, assignIncident);
  }

  removeConcrete(removeIncident) {
    let actionPath = `${this.subPath}/concrete/remove`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, removeIncident);
  }

    get(entityId) {
      let actionPath = `${this.subPath}/get/${entityId}`;
      console.log(`Get to: ${actionPath}`);
      
      return axiosService.doGet(actionPath);
    }

    find(projectId) {
      let actionPath = `${this.subPath}/find/${projectId}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

  getOpen(projectId) {
    let actionPath = `${this.subPath}/open/${projectId}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }
  
  list(ids) {
    let actionPath = `${this.subPath}/list`;
    console.log(`Post to: ${actionPath}`);

    return axiosService.doPost(actionPath, ids);
  }
    delete(entityId) {
      const actionPath = `${this.subPath}/delete/${entityId}`;
      console.log(`Delete to: ${actionPath}`);

      return axiosService.doDelete(actionPath);
    }

}

export const incidentService = new IncidentService();
