<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Alert Details</h2>
        <!--<Button type="button" label="ALERT" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Alert" @click="fnAddNew"></Button>-->
    </div>
    <alert-component :alertId="alertId"
                        :isOpen="isOpen"
                        @onCancel="fnCancel"
                        @onSave="fnOnSave"
                        @onAdd="fnOnAdd"
                        @onDelete="fnOnDelete"
                        @onError="fnShowToast"></alert-component>

    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
</template>
<script>
    import LoaderComponent from "@/components/LoaderComponent.vue";
    import AlertComponent from "@/components/AlertComponent";
    
    import { ToastSeverity } from 'primevue/api';
    
    export default {
        name: "AlertDetailsPage",
        components: {
            AlertComponent,
            LoaderComponent
        },
        data() {
            return {
                isLoading: false,
                alertId: undefined, 
                isOpen: false
            };
        },
        created() {
            console.log("Alert Details: Created");
            
            this.alertId = this.$route.params.alertId;
            console.log("alertId: " + this.$route.params.alertId);

            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        unmounted() {
            console.log("Unmounting Alert Manager Page...");
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        methods: {
            fnLoad: function () {
                console.log("Alert Details: loading - " + this.alertId);
                this.isLoading = true;
                this.isOpen = true;
                this.isLoading = false;
            },
            fnOnAdd(alertSaved) {
                this.fnShowToast('Save Success', 'The alert "' + alertSaved.name + '" was inserted!', ToastSeverity.SUCCESS);
            },
            fnOnSave(alertNew) {
                this.fnShowToast('Save Success', 'The alert "' + alertNew.name + '" was updated!', ToastSeverity.SUCCESS);
            },
            fnOnDelete(alertName) {

                this.fnShowToast('Delete Success', 'The alert "' + alertName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + alertName + '" was deleted!');
                this.fnCancel();
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCancel() {
                this.$router.push({ name: "alert-manager" });
            }
        }
    };

</script>
