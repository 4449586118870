<template>
  <div class="grid grid-cols-4 gap-4">
    <div v-for="(item, idx) in data" :key="idx">
      <Card class="card rounded-none shadow-xl border-t-4 border-t-indigo-500">
        <template #title>
          <div class="text-base flex justify-between">
            <div>{{ item.id.split(":")[2] }}</div>
            <div>
              <span class="mr-2">
                <i
                  class="pi pi-circle-fill"
                  style="font-size: 12px"
                  :style="{
                    color:
                      item.status.toLowerCase().trim() == 'active'
                        ? 'green'
                        : 'red',
                  }"
                ></i
              ></span>
              <span>{{ item.status != undefined ? item.status.toUpperCase() : "" }}</span>
            </div>
          </div>
        </template>
        <template #content>
          <div style="" v-for="(col, colIdx) in cols" :key="colIdx">
            <div class="m-2 p-2 border-2 border-slate-200 rounded">
              <div class="pb-2 font-semibold uppercase">{{ col }}</div>
              <div class="w-100 pb-2 px-2" v-html="fnParseValue(item[col])" />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "primevue/card";
import { parseJsonValue } from "@/utils/ParseJson.js";

export default {
  components: {
    Card,
  },
  props: {
    chartObject: Object,
    url: String,
  },
  data() {
    return {
      data: "",
      chart: "",
      dynamicColor: "white",
      conf: {},
      cols: [],
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Value");
        console.log(newValue);
        this.header = newValue.name;
        this.chart = newValue;
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      console.log("----- GETTING DATA OF MULTI CARDS WIDGET");
      console.log(`${this.$apiBase}/${this.url}`);
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {
          console.log(response);
          this.data = response.data.data;
          this.conf = response.data.conf;
          this.cols = response.data.cols;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      fnParseValue(text) {
          return parseJsonValue(text);
      },
  },
};
</script>
<style>
    .card {
        /*height: 450px;
   overflow: auto; */
    }
</style>
