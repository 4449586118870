<template>
  <div class="h-full w-full flex flex-col justify-center items-center mt-32">
    <h1 class="text-9xl font-extrabold text-dark tracking-widest">404</h1>
    <div class="bg-indigo-700 px-2 text-sm rounded rotate-12 absolute text-white">
      Page Not Found
    </div>
    <div class="pt-8">
        <Button label="Go Home"
                title="Go Home" @click="goHome"></Button>
    </div>
  </div>
</template>
<script>
    import Button from 'primevue/button';
export default {
  name: "ErrorPage",
  components: {
      Button
  },
  methods: {
    goHome() {
          this.$router.push({ name: "project-info" });
    },
  },
};
</script>
