import { axiosService } from "@/services/AxiosService.js";

class IngestService {
    
    constructor() {
      this.entityPath = "ingestion";
    }
    
    save(ingestObj) {
      let actionPath = `${this.entityPath}/save`;
      console.log(`Post to: ${actionPath}`);
      
      return axiosService.doPost(actionPath, ingestObj);
    }

    get(ingestId) {
      let actionPath = `${this.entityPath}/get/${ingestId}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

    list(project, role, stage) {
      let actionPath = `${this.entityPath}/list/${project}/${role}/${stage}`;
      console.log(`Get to: ${actionPath}`);

      return axiosService.doGet(actionPath);
    }

  find(project) {
    let actionPath = `${this.entityPath}/find/${project}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
    }

  delete(ingestId) {
    const actionPath = `${this.entityPath}/del/${ingestId}`;
    console.log(`Delete to: ${actionPath}`);

    return axiosService.doDelete(actionPath);
    }
}

export const ingestService = new IngestService();
