import { axiosService } from "@/services/AxiosService.js";

class UserService {

    constructor() {
      this.entityPath = "users";
    }

  save(userObj) {
    let savePath = `${this.entityPath}/save`;
    console.log(`Post to: ${savePath}`);

    return axiosService.doPost(savePath, userObj);
  }

  get(username) {
    let actionPath = `${this.entityPath}/get/${username}`;
    console.log(`Get to: ${actionPath}`);

    return axiosService.doGet(actionPath);
  }

  delete(username) {
    const actionPath = `${this.entityPath}/delete/${username}`;
    console.log(`Delete to: ${actionPath}`);

    return axiosService.doDelete(actionPath);
  }

  list() {
    let listPath = `${this.entityPath}/list`;
    console.log(`Get to: ${listPath}`);

    return axiosService.doGet(listPath);
  }

  setProject(username, project) {
    let setProjectPath = `${this.entityPath}/set-project`;
    console.log(`Post to: ${setProjectPath}`);
    let userObj = { username: username, projectId: project };

    return axiosService.doPost(setProjectPath, userObj);
  }
}

export const userService = new UserService();
