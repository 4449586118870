import { axiosService } from "@/services/AxiosService.js";

class IfcService {
    
    constructor() {
      this.entityPath = "ifc";
    }

    uploadIFC(fromdata) {
      let actionPath = `${this.entityPath}/upload`;
      console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, fromdata);
    }

    get(url) { 
      console.log(`Get to: ${url}`);

      return axiosService.doGet(url);
    }

}

export const ifcService = new IfcService();
