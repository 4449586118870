<template>
  <div class="px-8 z-10">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
