<template>
    <form id="frmDispose" name="frm-dispose" ref="frmDispose">
        <PrimeDialog v-model:visible="entity.isOpen" modal :header="textHeader" @hide="fnCancel"
                     :draggable="false" :style="{ width: '50rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <!--:disabled="dispose.reason!=='lifetime-end'"-->

            <div v-if="v$.entity.disposeType.$error" class="text-red-600 text-sm">Please enter a Dispose option.</div>
            <!-- Dispose Type -->
            <Dropdown v-model="entity.disposeType" :options="disposeStatus" class="w-full md:w-14rem mb-2"
                      optionLabel="name" optionValue="code" placeholder="Select a dispose option" />
            <div v-if="v$.entity.reason.$error" class="text-red-600 text-sm">Please enter a reason for the disposition.</div>
            <Textarea v-model="entity.reason" rows="6" class="w-full md:w-14rem" placeholder="Enter a reason to dispose the Robot."
                      v-if="entity.disposeType == disposeTypes.FAILED" />
            <template #footer>
                <Button label="Cancel" 
                        class="mr-2 bg-surface-400 border-surface-400 hover:bg-surface-500"
                        title="Cancel and go back" @click="fnCancel"></Button>
                <Button label="Dispose" severity="warning"
                        title="Dispose robot" @click="fnDispose"></Button>
            </template>

        </PrimeDialog>
    </form>
</template>

<script>
    import Textarea from 'primevue/textarea';
    import Dropdown from 'primevue/dropdown';
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";

    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core'
    // import { required} from '@vuelidate/validators'
    import { required, requiredIf } from '@vuelidate/validators'

    import { robotService } from "@/services/RobotService.js";
    import { capitalizeFirstLetter } from "@/utils/utils.js";
    import Constants from "@/utils/Constants.js";

    export default {
        name: "RobotDisposeComponent",
        emit: ["onError", "onClose", "onDispose"],
        setup() {
            return { v$: useVuelidate() }
        },
        components: {
            Textarea, Dropdown, Button,
            PrimeDialog
        },
        props: {
            entityId: String,
            entityName: String,
            isOpen: Boolean
        },
        data() {
            return {
                entity: {
                    entityId: undefined,
                    disposeType: undefined,
                    reason: "",
                    user: undefined,
                    isOpen: false
                },
                confirmObj: {
                    message: "",
                    isOpen: false
                },
                disposeStatus: [
                    { name: 'Failed', code: 'failed' },
                    { name: 'End of lifetime', code: 'lifetime-end' }
                ],
                textHeader: "",
                disposeTypes: Constants.Asset.DISPOSE_TYPE
            }
        },
        validations() {
            return {
                entity: {
                    disposeType: { required },
                    //reason: { required }
                    reason: { requiredIf: requiredIf(function () { return this.entity.disposeType == Constants.Asset.DISPOSE_TYPE.FAILED; }) }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {                    
                    console.log("Dispose Robot Component Opened!");
                    this.entity.entityId = this.entityId;
                    this.entity.isOpen = this.isOpen;
                    this.textHeader = "Dispose Robot: " + this.entityName;
                }
            }
        },
        methods: {
            fnDispose() {
                this.v$.$validate()
                    .then(result => {
                        //console.log(this.v$.alertObj.name.$errors);
                        //console.log(this.$store.getters.username);
                        
                        if (result) {
                            this.loading = true;
                            this.entity.user = this.$store.getters.username;
                            
                            console.log(JSON.stringify(this.entity));
                            robotService.dispose(this.entity)
                                .then((data) => {
                                    console.log(data);
                                    this.entity.disposeDate = new Date( data["dispose_date"]);
                                    this.$emit('onDispose', this.entity, this.entityName);

                                    this.loading = false;

                                    this.fnCleanForm();
                                    this.$emit('onClose');
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('onError', "Error", 'An error occurred when trying to dispose the Robot: "' + this.entityName + '"!!!', ToastSeverity.ERROR);
                                    this.loading = false;
                                });

                            this.loading = false;
                                
                            return true;
                        }
                        //else {
                        //    console.log(this.v$.alertObj.name);
                        //}

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                return false;
            },
            fnCancel() {
                this.fnCleanForm();
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    entityId: undefined,
                    disposeType: undefined,
                    reason: "",
                    user: undefined
                };
                this.$refs.frmDispose.reset();
                this.v$.$reset();
            },
            fnOpenConfirmBox: function () {
                this.confirmObj.message = "Do you really want to delete the alert: '" + this.alertObj.name + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
        }
    };
</script>
<style scoped>

</style>

