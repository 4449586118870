<template>
  <div>
    <h2 class="text-4xl leading-tight text-center my-4">
      Structural Integrity
    </h2>
    <div>
      <dynamic-widget-component
        tab="structural-integrity"
      ></dynamic-widget-component>
    </div>
  </div>
</template>

<script>
import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";

export default {
    name: "StructuralIntegrityPage",
    components: { DynamicWidgetComponent },
    mounted() {
        this.$logService.save(this.$options.name);
    },
};
</script>
