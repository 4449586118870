<template>
    <div>
        <input :type="type"
               class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
               :class="{'border-red-400 bg-red-100': hasError }"
               :placeholder="placeholder"
               :value="modelValue"
               @input="$emit('update:modelValue', $event.target.value)"
               :attr="attr"
               :name="name"
               :disabled="disabled" />
    </div>
</template>
<script>
    export default {
        name: "FormInput",
        emits: ['update:modelValue'],
        props: {
            name: String,
            type: String,
            placeholder: String,
            modelValue: String,
            attr: String,
            disabled: Boolean,
            hasError: Boolean
        },
        methods: {}
    };
</script>
