<template>
    <div class="custom-rich-data-table">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400"
             style="height: 500px; overflow: auto">
            <h3 class="text-indigo-600 text-center text-xl my-1"
                style="text-transform: uppercase; font-weight: bold">
                {{ chart["name"] }}
            </h3>
            <div class="text-xs">Click on each row to see details.</div>
            <DataTable stripedRows
                       :value="tableRows"
                       tableStyle="min-width: 35rem; font-size: 14px;"
                       paginator
                       :rows="5"
                       :loading="loading"
                       @rowSelect="onRowSelect"
                       selectionMode="single">
                <Column v-for="(column, colIdx) in columns"
                        :key="colIdx"
                        :field="column"
                        :header="column"></Column>
            </DataTable>
        </div>
        <div>
            <PrimeDialog v-model:visible="showModel"
                         maximizable
                         modal
                         header="View"
                         :style="{ width: '50rem' }"
                         :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <div v-for="(value, key) in selectedRow" :key="key" class="m-5 p-5 row">
                    <div class="font-bold key mb-4">
                        <u>{{ fnCapText(key) }}</u>
                    </div>
                    <table>
                        <tbody>
                            <tr v-for="(itemValue, itemKey) in value"
                                :key="itemKey"
                                class="m-5 p-5 row">
                                <td class="font-bold key">{{ itemKey }}</td>
                                <td>:</td>
                                <td class="m-2 p-3 block bg-violet-50">
                                    <div v-html="fnParseValue(itemValue)" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PrimeDialog>
            <div>
                <PrimeDialog v-model:visible="showImageModel"
                             image-modal
                             header="View"
                             :style="{ width: '50rem' }"
                             :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                    <img width="600" :src="imageUrl" alt="Picture.." />
                </PrimeDialog>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import axios from "axios";
    import PrimeDialog from "primevue/dialog";
    import { capitalizeFirstLetter } from "@/utils/utils.js";
    import { parseJsonValue } from "@/utils/ParseJson.js";
     
    export default {
        components: {
            DataTable,
            Column,
            PrimeDialog,
        },
        props: {
            chartObject: Object,
            url: Object,
        },
        data() {
            return {
                columns: [],
                title: "",
                tableRows: [],
                chart: {},
                loading: false,
                showModel: false,
                showImageModel: false,
                selectedRow: {},
                base64String: "",
                imageUrl: "",
            };
        },
        watch: {
            chartObject: {
                immediate: true,
                handler(newValue) {
                    //console.log("tableTitle Chart:", newValue);
                    this.chart = newValue;
                    this.loadData(newValue);
                },
            },
        },
        methods: {
            onRowSelect(event) {
                //console.log(event.data);
                axios
                    .get(
                        `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${event.data.id}`
                    )
                    .then((response) => {
                        console.log("----- ROW -----");
                        //console.log(response.data);
                        this.selectedRow = response.data.data;
                        if ("image" in this.selectedRow) {
                            this.base64String = this.selectedRow.image;
                            this.convertBase64ToImage();
                            this.showImageModel = true;
                        } else if (Object.keys(this.selectedRow).length > 0) {
                            this.showModel = true;
                        }
                    })
                    .catch((error) => {
                        console.error("Error loading data:", error);
                    });
            },
            //async loadData(chart) {
            async loadData() {
                this.loading = true;
                //const conf = chart.configuration;
                //console.log(conf);
                //console.log(`Get Custom Data from: ${this.$apiBase}/${this.url}`);
                axios
                    .get(`${this.$apiBase}/${this.url.onLoad}`)
                    .then((response) => {
                        this.tableRows = response.data.data;
                        this.columns = response.data.cols;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                        return [];
                    });
            },
            convertBase64ToImage() {
                const dataURIprefix = "data:image/";
                let dataURI = this.base64String;

                if (!this.base64String.startsWith(dataURIprefix)) {
                    const mimeType = "image/png"; // Change to 'image/jpeg' or other type if needed
                    dataURI = `${dataURIprefix}${mimeType};base64,${this.base64String}`;
                }

                this.imageUrl = dataURI;
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnParseValue(text) {
                return parseJsonValue(text);
            },
        },
    };
</script>

