<template>
    <h2 class="text-4xl leading-tight text-center">Ingestion Manager</h2>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                INGESTION RULES
            </h3>
            <DataTable :value="tableData" stripedRows scrollable class="w-full max-h-72">
                <Column field="name" header="Name"></Column>
                <Column field="description" header="Description"></Column>
                <Column field="dataSource.name" header="Source data"></Column>
                <Column field="dataDestination.name" header="Destination data"></Column>
                <Column field="status" header="Status">
                    <template #body="slotProps">
                        {{ slotProps.data.status == 1 ? "Started" : "Stopped" }}
                        <!--<Tag :value="slotProps.data.Status" :severity="getStatusLabel(slotProps.data.inventoryStatus)" />-->
                    </template>
                </Column>
                <Column :exportable="false" header="Start/Stop">
                    <template #body="slotProps">
                        <img src="../assets/loader-loading.gif" alt="Loading..." class="h-[24px] w-[24px]"
                             v-if="slotProps.data.loading" />
                        <Button icon="pi pi-stop" outlined rounded severity="danger"
                                @click="fnStopIngest(slotProps.data.ingestId)" v-else-if="slotProps.data.status == 1" />
                        <Button icon="pi pi-play" outlined rounded severity="success"
                                @click="fnStartIngest(slotProps.data.ingestId)" v-else />
                    </template>
                </Column>
                <!--<Column :exportable="false">
        <template #body="slotProps">
            <Button icon="pi pi-pencil" outlined rounded class="mr-2" @click="fnOpenConfBox(slotProps.data.ingestId, slotProps.data.name)" />
            <Button icon="pi pi-trash" outlined rounded severity="danger" @click="fnEdit(slotProps.data.ingestId)" />
        </template>
    </Column>-->
            </DataTable>
        </div>
    </div>
    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
    <confirmation-component :msg="confirmObj.message"
                            :isConfirmationOpen="confirmObj.isOpen"
                            @close="fnCloseConfBox"
                            @ok="fnDelete"></confirmation-component>
    <!--<ingest-component :ingestId="alerComp.ingestId"
                        :isOpen="alerComp.isOpen"
                        @close="fnCloseIngestComp"
                        @onSave="fnOnSave"
                        @onAdd="fnOnAdd"
                        @onDelete="fnOnDelete"
                        @onError="fnShowToast"></ingest-component>-->
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import ConfirmationComponent from "@/components/ConfirmationComponent.vue";
    // import IngestComponent from "@/components/IngestComponent";

    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import { ToastSeverity } from 'primevue/api';

    import { ingestService } from "../services/IngestService.js";
    import Constants from "../utils/Constants.js";
    
    export default {
        name: "IngestionManagerPage",
        components: {
            //IngestComponent,
            ConfirmationComponent,
            LoaderComponent,
            DataTable,
            Column,
            Button
        },
        data() {
            return {
                isLoading: false,
                tableData: [],
                confirmObj: {
                    message: "",
                    isOpen: false,
                    ingestId: "",
                    ingestName: ""
                },
                ingestComp: {
                    ingestId: undefined,
                    isOpen: false
                },
                connections: []
            };
        },
        created() {
            console.log("Ingest Manager: Created");
            this.$store.dispatch("parseHash").
                then(() => {
                    //this.user.username = this.$store.getters.username;
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Ingest Manager Page...");

            this.connections.forEach(function (item, index, arr) {

                var conn = item.conn;

                if (conn && conn.readyState == 1) {
                    console.log("Websocker connection closing...");
                    arr[index].conn.close();
                }
                
            });
        },
        methods: {
            fnReceiveMessage(event) {
                console.log("An event has arrived - Start/Stop!!!");
                //console.log(event.data);
                let dssIngest = JSON.parse(event.data);

                if (dssIngest.action) {
                    console.log("dssIngest has an 'action'.");
                    return;
                }

                var icon = ToastSeverity.ERROR;
                if (dssIngest.success) {
                    icon = ToastSeverity.SUCCESS;
                }
                
                //console.log(dssIngest.ingestId);
                let ingestId = dssIngest.ingestId;
                
                const currentIndex = this.tableData.findIndex(p => p.ingestId === ingestId);
                //console.log(currentIndex);
                
                this.fnShowMessage("Ingestion manager message.", dssIngest.message.replace(ingestId, this.tableData[currentIndex].name), icon);

                //Update the table with the new data.
                this.tableData[currentIndex].loading = false;
                if (dssIngest.started)
                {
                    this.tableData[currentIndex].status = 1;
                    //The process is started!
                }
                else if (dssIngest.stopped)
                {
                    this.tableData[currentIndex].status = 0;
                    //The process is stopped!
                }

                var item = this.connections.find(e => e.id === ingestId);
                if (item != undefined && item.conn != undefined && item.conn.readyState == 1) {
                    item.conn.onclose = (event) => {
                        console.log("Websocket connection closed...");
                        console.log(event);
                        this.connections.splice(this.connections.findIndex(e => e.id === ingestId), 1);
                    };
                    console.log("Websocket connection closing...");
                    item.conn.close();
                }
            },
            fnStartIngest(ingestId){
                this.fnStartStopIngest(ingestId, Constants.IngestCommands.START);
            },
            fnStopIngest(ingestId){
                this.fnStartStopIngest(ingestId, Constants.IngestCommands.STOP);
            },
            fnStartStopIngest(ingestId, action){
                console.log(action + " Ingest!");
                var data = {
                    ingestId: ingestId,
                    action: action
                };
                let socket = new WebSocket(this.$ingestWS);
                
                socket.onmessage = this.fnReceiveMessage;
                socket.onopen = (event) => {
                    console.log(event);
                    console.log("Successfully connected to the Management websocket server...");
                    socket.send(JSON.stringify(data));
                };
                
                this.connections.push({ "id": ingestId, "conn": socket });

                //TODO: Show a message that the command has been sent... or a small loading gift...
                const currentIndex = this.tableData.findIndex(p => p.ingestId === ingestId);
                this.tableData[currentIndex].loading = true;
            },
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];

                //Get the ingestion records for the current project.
                ingestService.find(this.$store.getters.project)
                    .then((data) => {
                        //console.log(data);

                        if (data != undefined && Array.isArray(data)) {
                            this.tableData = data;
                        }

                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast("Error.",'Error when loading the ingestion items...!!!', ToastSeverity.ERROR)
                    });
                return;
            },
            fnOnAdd(ingestSaved) {
                this.fnShowToast("Insert Success", 'The ingest "' + ingestSaved.name + '" was inserted!', ToastSeverity.SUCCESS);
                
                this.tableData.push(ingestSaved);
            },
            fnOnSave(ingestNew) {
                this.fnShowToast("Save Success", 'The ingest "' + ingestNew.name + '" was updated!', ToastSeverity.SUCCESS);
                
                const currentIndex = this.tableData.findIndex(p => p.ingestId === ingestNew.ingestId);
                console.log(currentIndex); console.log(ingestNew);

                this.tableData.splice(currentIndex, 1, ingestNew);
            },
            fnOnDelete(ingestId, ingestName) {

                this.fnShowToast("Deleted", 'The ingest "' + ingestName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The ingest "' + ingestName + '" was deleted!');

                const currentIndex = this.tableData.findIndex(p => p.ingestId === ingestId);
                
                this.tableData.splice(currentIndex, 1);
            },
            fnDelete() {
                this.loading = true;

                ingestService.delete(this.confirmObj.ingestId)
                    .then(() => {
                        //.then((data) => {
                        //console.log(data);
                        this.fnOnDelete(this.confirmObj.ingestId, this.confirmObj.ingestName);

                        this.confirmObj.message = "";
                        this.confirmObj.isOpen = false;
                        this.confirmObj.ingestId = "";
                        this.confirmObj.ingestName = "";

                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fnShowToast("Error", 'An error occurred when trying to delete the ingest "' + this.confirmObj.ingestName + '"!!!', ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnOpenConfBox: function (ingestId, ingestName) {
                this.confirmObj.ingestId = ingestId;
                this.confirmObj.ingestName = ingestName;
                this.confirmObj.message = "Do you really want to delete the ingest: '" + ingestName + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfBox() {
                this.confirmObj.ingestId = "";
                this.confirmObj.ingestName = "";
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnAddNew() {
                this.ingestComp.ingestId = "";
                this.ingestComp.isOpen = true;
            },
            fnEdit: function (ingestId) {
                this.ingestComp.ingestId = ingestId;
                this.ingestComp.isOpen = true;
            },
            fnCloseIngestComp() {
                this.ingestComp.isOpen = false;
                this.ingestComp.ingestId = "";
            }
        }
    };

</script>
