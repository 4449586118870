import { axiosService } from "@/services/AxiosService.js";

class RobotService {

    constructor() {
        this.subPath = "robots";
    }
    
    save(entity) {
        let actionPath = `${this.subPath}/save`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    dispose(entity) {
        let actionPath = `${this.subPath}/dispose`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    addWork(entity) {
        let actionPath = `${this.subPath}/work/add`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    addMaintenace(entity) {
        let actionPath = `${this.subPath}/maintenance/add`;
        console.log(`Post to: ${actionPath}`);

      return axiosService.doPost(actionPath, entity);
    }

    get(entityId) {
        var urlPath = `${this.subPath}/get/${entityId}`;
        console.log(`Get to: ${urlPath}`);

      return axiosService.doGet(urlPath);
    }

    active(project) {
        let urlPath = `${this.subPath}/active/${project}`;
        console.log(`Get to: ${urlPath}`);

      return axiosService.doGet(urlPath);
    }

    find(project) {
        let urlPath = `${this.subPath}/find/${project}`;
        console.log(`Get to: ${urlPath}`);

      return axiosService.doGet(urlPath);
    }

    delete(entityId) {
      const pathDel = `${this.subPath}/del/${entityId}`;
      console.log(`Delete to: ${pathDel}`);

      return axiosService.doDelete(pathDel);
    }
}

export const robotService = new RobotService();
