<template>
    <h2 class="text-4xl leading-tight text-center">Anomaly Detection</h2>
    <dynamic-widget-component tab="anomalies"></dynamic-widget-component>
</template>

<script>
import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
export default {
        name: "AnomalyDetectionPage",
  components: {
    DynamicWidgetComponent,
    },
    mounted() {
        this.$logService.save(this.$options.name);
    }
};
</script>
