<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Preset Alerts Manager</h2>
        <div class="ml-auto">
            <Button type="button" label="Back" icon="pi pi-arrow-circle-left" iconPos="left" class="mr-2 bg-surface-400 border-surface-400 hover:bg-surface-500"
                    title="Create a custom Alert" @click="fnBackAlert"></Button>
            <Button type="button" label="Custom Alert" icon="pi pi-plus-circle" iconPos="right"
                    title="Create a custom Alert" @click="fnCustomAlert"></Button>
        </div>
    </div>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                PREDEFINED ALERTS
            </h3>
            <DataTable :value="tableData" stripedRows class="w-full" size="small"
                       paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                       scrollable sortField="createdAt" :sortOrder="1"
                       v-model:filters="filters" filterDisplay="row" :loading="isLoading"
                       :globalFilterFields="filterFields">
                <template #empty>
                    No preset alerts found.
                </template>
                <template #loading>
                    Loading preset alerts data. Please wait...
                </template>
                <Column field="category" header="Category" sortable>
                    <template #body="{ data }">
                        {{ fnCapText(data.category) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback" class="w-[10rem]" placeholder="Search by category" size="small" />
                    </template>
                </Column>
                <Column field="role" header="Role" sortable>
                    <template #body="{ data }">
                        {{ fnCapText(data.role) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback" class="w-[10rem]" placeholder="Search by role" size="small" />
                    </template>
                </Column>
                <Column field="phase" header="Phase" sortable>
                    <template #body="{ data }">
                        {{ fnCapText(data.phase) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback" class="w-[10rem]" placeholder="Search by phase" size="small" />
                    </template>
                </Column>
                <Column field="name" header="Name" sortable>
                    <template #body="{ data }">
                        {{ data.name }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback" class="w-[10rem]" placeholder="Search by name" size="small" />
                    </template>
                </Column>
                <Column field="severity" header="Severity" sortable>
                    <template #body="{ data }">
                        {{ fnCapText(data.severity) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback" class="w-[10rem]" placeholder="Search by severity" size="small" />
                    </template>
                </Column>
                <Column field="sourceName" header="Data Source" sortable>
                    <template #body="{ data }">
                        {{ fnCapText(data.sourceName) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" @input="filterCallback" class="w-[10rem]" placeholder="Search by source" size="small" />
                    </template>
                </Column>
                <Column :exportable="false">
                    <template #body="slotProps">
                        <Button icon="pi pi-file" outlined rounded size="small" title="Edit"
                                @click="fnDetails(slotProps.data.presetId)" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
   
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    
    import { ToastSeverity } from 'primevue/api';
    import { FilterMatchMode } from 'primevue/api';

    import { presetAlertService } from "../services/PresetAlertService.js";
    import { capitalizeFirstLetter } from "../utils/utils.js";
   
    export default {
        name: "PresetAlertPage",
        components: {
            LoaderComponent,
            DataTable, Column, 
            Button, InputText
        },
        data() {
            return {
                isLoading: false,
                tableData: [],
                filterFields: ['role', 'phase', 'name', 'category', 'severity', 'sourceName'],
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    category: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    role: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    phase: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    severity: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    sourceName: { value: null, matchMode: FilterMatchMode.CONTAINS }
                }
            };
        },
        created() {
            console.log("Preset Alerts Page: Created");
            this.$store.dispatch("parseHash").
                then(() => {
                    //this.user.username = this.$store.getters.username;
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Preset Alerts Page...");
        },
        methods: {
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];
                //Calling the preset service to get the data.
                presetAlertService.find()
                    .then((data) => {
                        
                        if (data != undefined && Array.isArray(data)) {
                            this.tableData = data;
                        }
                        this.tableLoader = false;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast('Error', 'Error when loading Preconfigured Alerts data...!!!', ToastSeverity.ERROR)
                    });
                return;
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnDetails: function (presetId) {
                this.$router.push({
                    name: "preset-details",
                    params: { presetId: presetId }
                });
            },
            fnCustomAlert(){
                this.$router.push({
                    name: "alert-details",
                    params: { alertId: "" }
                });
            },
            fnBackAlert(){
                this.$router.push({ name: "alert-manager" });
            },
            fnCapText(text){
                return capitalizeFirstLetter(text);
            }
        }
    };

</script>

